import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Input,
  Stack,
  Box,
  CircularProgress,
  Modal,
} from "@mui/material";

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import DownloadIcon from "@mui/icons-material/Download";
import { useNavigate } from "react-router-dom";
import { _api } from "../utilities/api";
import MySounds from "./MySounds";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";

const OrdersList = () => {
  const [open, setOpen] = useState(false);
  const [sounds, setSounds] = useState(false);
  const [user, setUser] = useState(null)
  const navigate = useNavigate();

  // const { product } = useParams();
  const [isPageLoad, setPageLoad] = useState(false);
  const [listData, setListData] = useState([]);
  const [viewOrder, setViewOrder] = useState(false);
  const [activeOrder, setActiveOrder] = useState(false)
  const [activeIndex, setActiveIndex] = useState(-1)

  useEffect(() => {
    let u = window.localStorage.getItem("gms_user")
    setUser(JSON.parse(u))
  }, [])

  const handleViewOrderOpen = (row, index) => {
    setActiveOrder(row)
    setActiveIndex(index)
    setViewOrder(true)
  };
  const handleViewOrderClose = () => {
    setActiveOrder(false)
    setActiveIndex(-1)
    setViewOrder(false)
  };

  const handleOpen = (order) => {
    setSounds(order.sounds);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!user) return;

    let userId = user?._id;
    if (!userId) {
      alert("No UserId Found");
      return;
    }

    setPageLoad(false);
    _api({
      method: "POST",
      url: user?.role == 1 ? `/my-orders` : "/orders",
    })
      .then((res) => {
        if (res?.data) {
          console.log(res?.data?.data?.lists);
          setListData(res?.data?.data?.lists);
        }
        setPageLoad(true);
      })
      .catch((_) => {
        setPageLoad(true);
      });
  }, [user]);

  const _viewerOrder = () => {
    return (
      <Modal
        open={viewOrder}
        onClose={handleViewOrderClose}
        aria-labelledby="modal-modal-title2"
        aria-describedby="modal-modal-description2"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: "80%",
          overflow: "auto",
          maxHeight: "80vh",
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: "10px",
        }}>
          <div>
            <Stack direction={"row"} className='order_heading'>
              <div>
                #{activeOrder?.orderId}
              </div>
              <span></span>
              <div>
                {activeOrder?.orderDetails?.orderName}
              </div>
              <span></span>
              <div>
                Rs. {activeOrder?.pricing?.subTotal}/-
              </div>
              <span></span>
              <div className="uppercase">
                {activeOrder?.status}
              </div>
              <span></span>
              <div>
                {new Date(activeOrder.createdAt).toLocaleString()}
              </div>
            </Stack>
            <hr />
            <br />
            <Accordion style={{ background: "whitesmoke" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <b>Order Details</b>
              </AccordionSummary>
              <AccordionDetails>
                <table className='admin_order_table'>
                  <tr>
                    <th>Order Name</th>
                    <td>{activeOrder?.orderDetails?.orderName}</td>
                  </tr>
                  <tr>
                    <th>Product</th>
                    <td>{activeOrder?.orderDetails?.product}</td>
                  </tr>
                  <tr>
                    <th>Quantity</th>
                    <td>{activeOrder?.orderDetails?.quantity}</td>
                  </tr>
                  <tr>
                    <th>Size</th>
                    <td>{activeOrder?.orderDetails?.size}</td>
                  </tr>
                  <tr>
                    <th>Paper</th>
                    <td>{activeOrder?.orderDetails?.paper}</td>
                  </tr>
                  <tr>
                    <th>Print Color</th>
                    <td>{activeOrder?.orderDetails?.printColor}</td>
                  </tr>
                  <tr>
                    <th>Print Sides</th>
                    <td>{activeOrder?.orderDetails?.printSides}</td>
                  </tr>
                  <tr>
                    <th>Binding</th>
                    <td>{activeOrder?.orderDetails?.binding}</td>
                  </tr>
                  <tr>
                    <th>Shipping</th>
                    <td>{activeOrder?.orderDetails?.shipping}</td>
                  </tr>
                  <tr>
                    <th>PDF File</th>
                    <td>
                      <a target="blank" href={`${process.env.REACT_APP_SERVER_URL}/uploads/${activeOrder?.orderDetails?.printingFile}`}>Click Here</a>
                    </td>
                  </tr>
                </table>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ background: "whitesmoke" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <b>Billing Details</b>
              </AccordionSummary>
              <AccordionDetails>
                <table className='admin_order_table'>
                  <tr>
                    <th>First Name</th>
                    <td>{JSON.parse(activeOrder?.address || "{}")?.b_firstName}</td>
                  </tr>
                  <tr>
                    <th>Last Name</th>
                    <td>{JSON.parse(activeOrder?.address || "{}")?.b_lastName}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{JSON.parse(activeOrder?.address || "{}")?.b_email}</td>

                  </tr>
                  <tr>
                    <th>Phone</th>
                    <td>{JSON.parse(activeOrder?.address || "{}")?.b_phone}</td>
                  </tr>
                  <tr>
                    <th>Street Address</th>
                    <td>{JSON.parse(activeOrder?.address || "{}")?.b_streetAddress}</td>
                  </tr>
                  <tr>
                    <th>Landmark</th>
                    <td>{JSON.parse(activeOrder?.address || "{}")?.b_landmark}</td>
                  </tr>
                  <tr>
                    <th>Zip Code</th>
                    <td>{JSON.parse(activeOrder?.address || "{}")?.b_zipcode}</td>
                  </tr>
                  <tr>
                    <th>city</th>
                    <td>{JSON.parse(activeOrder?.address || "{}")?.b_city}</td>
                  </tr>
                  <tr>
                    <th>country</th>
                    <td>{JSON.parse(activeOrder?.address || "{}")?.b_country}</td>
                  </tr>
                  <tr>
                    <th>GST Number</th>
                    <td>{JSON.parse(activeOrder?.address || "{}")?.gst_number}</td>
                  </tr>
                </table>
              </AccordionDetails>
            </Accordion>

            <Accordion style={{ background: "whitesmoke" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <b>Shipping Details</b>
              </AccordionSummary>
              <AccordionDetails>
                <table className='admin_order_table'>
                  <tr>
                    <th>Shipping Type</th>
                    <td><b>{activeOrder?.orderDetails?.shipping}</b></td>
                  </tr>

                  {
                    ["COURIER", "TRANSPORT"].includes(activeOrder?.orderDetails?.shipping) ? <>
                      <tr>
                        <th>First Name</th>
                        <td>{JSON.parse(activeOrder?.address || "{}")?.s_firstName}</td>
                      </tr>
                      <tr>
                        <th>Last Name</th>
                        <td>{JSON.parse(activeOrder?.address || "{}")?.s_lastName}</td>
                      </tr>
                      <tr>
                        <th>Email</th>
                        <td>{JSON.parse(activeOrder?.address || "{}")?.s_email}</td>

                      </tr>
                      <tr>
                        <th>Phone</th>
                        <td>{JSON.parse(activeOrder?.address || "{}")?.s_phone}</td>
                      </tr>
                      <tr>
                        <th>Street Address</th>
                        <td>{JSON.parse(activeOrder?.address || "{}")?.s_streetAddress}</td>
                      </tr>
                      <tr>
                        <th>Landmark</th>
                        <td>{JSON.parse(activeOrder?.address || "{}")?.s_landmark}</td>
                      </tr>
                      <tr>
                        <th>Zip Code</th>
                        <td>{JSON.parse(activeOrder?.address || "{}")?.s_zipcode}</td>
                      </tr>
                      <tr>
                        <th>city</th>
                        <td>{JSON.parse(activeOrder?.address || "{}")?.s_city}</td>
                      </tr>
                      <tr>
                        <th>country</th>
                        <td>{JSON.parse(activeOrder?.address || "{}")?.s_country}</td>
                      </tr></> :
                      <>
                        <h1 className="font-bold my-2 mt-4 text-lg">Order Pickup address</h1>
                        <address>
                          <pre>
                            GILLSON MEDIA SOLUTIONS <br />
                            Scf 116A, Phase-5, Industrial Area, Mohali <br />
                            0172-4031356, 9872005082 <br />
                          </pre>
                        </address></>

                  }
                </table>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                <b>Payment Details</b>
              </AccordionSummary>
              <AccordionDetails>
                <table className='admin_order_table'>
                  <tr>
                    <th>Cost</th>
                    <td>Rs. {activeOrder?.pricing?.cost}/-</td>
                  </tr>
                  <tr>
                    <th>GST</th>
                    <td>Rs. {activeOrder?.pricing?.gst}/-</td>
                  </tr>
                  <tr>
                    <th>Sub Total</th>
                    <td>Rs. {activeOrder?.pricing?.subTotal}/-</td>
                  </tr>
                  <tr>
                    <th>Amount Payable</th>
                    <td>Rs. {activeOrder?.pricing?.amountPayable}/-</td>
                  </tr>
                  <tr>
                    <th>QR File</th>
                    <td>
                      <a target="_blank" href={`${process.env.REACT_APP_SERVER_URL}/uploads/${activeOrder?.payment?.qrFile}`}>Click Here</a>
                    </td>
                  </tr>
                </table>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4-content"
                id="panel4-header"
              >
                <b>Customer Details</b>
              </AccordionSummary>
              <AccordionDetails>
                <table className='admin_order_table'>
                  <tr>
                    <th>UserID</th>
                    <td>{activeOrder?.user?._id}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{activeOrder?.user?.email}</td>
                  </tr>
                  <tr>
                    <th>Full Name</th>
                    <td>{activeOrder?.user?.fullName || "--"}</td>
                  </tr>
                </table>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5-content"
                id="panel5-header"
              >
                <b>Track History</b>
              </AccordionSummary>
              <AccordionDetails>
                <table className='admin_order_table'>
                  {
                    ["placed", "processing", "shipping", "delivered", "canceled", "rejected"]?.map(s => {
                      return (
                        <tr key={s}>
                          <th className="uppercase">{s}</th>
                          <td>{activeOrder?.statusDetails?.[s] ? new Date(activeOrder?.statusDetails?.[s])?.toLocaleString() : "--"}</td>
                        </tr>
                      );
                    })
                  }
                </table>
              </AccordionDetails>
            </Accordion>
          </div>
        </Box>
      </Modal>
    );
  };

  const handleStatusChange = (e, oid, index) => {
    const { value } = e.target;

    _api({
      method: "POST",
      url: `/orders/${oid}/update`,
      data: {
        status: value,
      }
    }).then((res) => {
      let oldd = [...listData];
      oldd[index].status = value;
      setListData(oldd)
    }).catch((_) => {
    });
  }

  return (
    <div className="container" style={{ minHeight: "100vh", paddingTop: "10px" }}>
      {_viewerOrder()}
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          mb: "20px",
          mt: "40px",
        }}
      >
        <Box>
          {/* <Typography gutterBottom>
                        Orders List
                    </Typography> */}
        </Box>
        <Box>
          <Input placeholder="search order" />
        </Box>
      </Stack>
      <TableContainer sx={{ background: "transparent" }}>
        {!isPageLoad ? (
          <center
            style={{
              marginTop: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              // justifyContent: "center",
              minHeight: "100vh",
            }}
          >
            <CircularProgress />
            <span style={{ marginTop: "20px", fontSize: "15px" }}>
              Please wait...
            </span>
            <span className="loading_title">Loading your orders</span>
          </center>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>OrderID</TableCell>
                <TableCell>Order Name</TableCell>
                <TableCell>Product</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Status</TableCell>
                {/* <TableCell>Action</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {listData.map((order, index) => (
                <TableRow key={order.id} >
                  <TableCell
                    sx={{ cursor: "pointer", ":hover": { color: "black", textDecoration: "underline" } }}
                    onClick={() => {
                      handleViewOrderOpen(order, index)
                    }}
                  >#{order.orderId}</TableCell>
                  <TableCell>{order?.orderDetails?.orderName}</TableCell>
                  <TableCell>{order?.orderDetails?.product}</TableCell>
                  <TableCell>
                    {new Date(order.createdAt).toLocaleDateString()}
                  </TableCell>
                  <TableCell>Rs. {order.pricing?.subTotal}/-</TableCell>
                  {
                    user?.role == 1 ?
                      <TableCell className="uppercase">{order.status}</TableCell> :
                      <TableCell className="uppercase">
                        <select value={order.status} id="status-select-input" className="status-select uppercase" name="status"
                          onChange={(e) => handleStatusChange(e, order?._id, index)}>
                          {
                            ["placed", "processing", "shipping", "delivered", "canceled", "rejected"]?.map(s => {
                              return <option value={s} className="uppercase">{s}</option>
                            })
                          }
                        </select>
                      </TableCell>
                  }
                  {/* <TableCell align="left">
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                    >
                      {getActionButton(order)}
                    </Stack>
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </div>
  );
};

export default OrdersList;
