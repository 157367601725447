import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { useTable } from 'react-table';
import 'chart.js/auto';
import { _api } from '../utilities/api';

const Dashboard = () => {
    const [orderData, setOrderData] = useState([]);
    const [userData, setUserData] = useState([]);

    useEffect(() => {
        // Fetch order data and user data from your API
        // For now, we'll use static data for demonstration

        const fetchData = async () => {
            const orders = [
                { date: '2024-07-01', count: 10 },
                { date: '2024-07-02', count: 20 },
                { date: '2024-07-03', count: 12 },
                { date: '2024-07-04', count: 15 },
                { date: '2024-07-05', count: 15 },
                { date: '2024-07-06', count: 25 },
                { date: '2024-07-07', count: 15 },
                { date: '2024-07-08', count: 15 },
                { date: '2024-07-09', count: 25 },
                { date: '2024-07-10', count: 25 },
                { date: '2024-07-07', count: 15 },
                { date: '2024-07-08', count: 15 },
                { date: '2024-07-09', count: 25 },
                { date: '2024-07-10', count: 25 },
                { date: '2024-07-02', count: 20 },
                // ... more data
            ];


            let userReponse = await _api({
                method: "POST",
                url: "/users",
                data: {
                    filter: [{ role: { $ne: 0 } }]
                }
            })
            console.log(userReponse);
            const users = userReponse?.data?.data?.lists;

            setOrderData(orders);
            setUserData(users);
        };

        fetchData();
    }, []);

    const getChartData = () => {
        const labels = orderData.map(order => order.date);
        const data = orderData.map(order => order.count);

        return {
            labels,
            datasets: [
                {
                    label: 'Daily Orders',
                    data,
                    borderColor: 'rgba(75,192,192,1)',
                    backgroundColor: 'rgba(75,192,192,0.2)',
                },
            ],
        };
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'User Id',
                accessor: 'userID',
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
            {
                Header: 'Special User',
                accessor: 'isSpecial',
                Cell: ({ row }) => (
                    <input
                        type="checkbox"
                        defaultChecked={Boolean(row.original.isSpecial)}
                        onChange={(e) => {
                            console.log({ row });

                            _api({
                                method: "POST",
                                url: `/users/${row.original._id}/update`, // Using userID from the row data
                                data: {
                                    isSpecial: e.target.checked
                                }
                            })
                        }}
                        className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                    />
                ),
            },
        ],
        []
    );

    const data = React.useMemo(() => userData, [userData]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data });

    return (
        <div className="container mx-auto min-h-screen p-4">
            <h1 className="text-2xl font-bold mb-4">Dashboard</h1>

            <div className="mb-8">
                <Line data={getChartData()} height={"100px"} />
            </div>

            <div>
                <h2 className="text-xl font-semibold mb-2">Users List</h2>
                <table {...getTableProps()} className="min-w-full bg-white">
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th
                                        {...column.getHeaderProps()}
                                        className="py-2 px-4 border-b border-gray-200 bg-gray-100 text-left text-sm font-semibold text-gray-600"
                                    >
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => (
                                        <td
                                            {...cell.getCellProps()}
                                            className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700"
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Dashboard;
