import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { _api } from "../utilities/api";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Card, CardContent, CardHeader, CircularProgress, FormControl, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from "@mui/material";
import { ProductList } from "../components/ProductList";
import { SubProductList } from "../components/SubProductList";
import OrderForm from "./OrderForm";

import React from 'react';

const ProductDescription = () => {
    return (
        <div className="p-6 max-w-4xl mx-auto bg-white rounded-lg shadow-md">
            <div className="mb-6">
                <h2 className="text-blue-700 text-xl font-bold mb-2">Product Description</h2>
                <ul className="list-disc pl-5">
                    <li>Product Ref.: <strong>PP/02nd Edition (Sample File)</strong></li>
                    <li>Product Code: <strong>PP-1</strong></li>
                    <li>Product Class: <strong>Regular</strong></li>
                    <li>Product Size: <strong>Letter Size (8.5" X 11")</strong></li>
                    <li>Product Core: <strong>Excellent Printing with Latest Machines</strong></li>
                    <li>Production Time: <strong>Within 48 hours from file upload</strong></li>
                    <li>Paper Quality: <strong>70 GSM Maplitho Paper</strong></li>
                    <li>Printing Options: <strong>Single Side</strong></li>
                    <li>Price discount applicable (System auto calculate) with increase in Quantity</li>
                </ul>
            </div>
            <div>
                <h2 className="text-blue-700 text-xl font-bold mb-2">Our Specialization</h2>
                <ul className="list-disc pl-5">
                    <li>Printing with latest Komori offset machines (2023 Model)</li>
                    <li>Innovative, Advanced & Equipped Post Printing Unit</li>
                    <li>Constant quality with reasonable price</li>
                </ul>
            </div>
        </div>
    );
};



const NewOrder = () => {
    const navigate = useNavigate();
    const { product } = useParams();
    const [isPageLoad, setPageLoad] = useState(false)
    const [listData, setListData] = useState([])

    useEffect(() => {
        let u = window.localStorage.getItem("gms_user")
        if(!u){
            navigate("/login")
        }
    }, [])


    return (<>
        <div id="new-order" className="p-2 pt-4 sm:pt-0 sm:p-4 flex flex-row justify-center">
            <OrderForm />
            {/* <div className="w-1/2 ml-4 h-screen hidden sm:block">
                <img src="https://media.cnn.com/api/v1/images/stellar/prod/best-photo-printers-top-image.jpg?c=16x9" alt=""
                    className="rounded shadow-sm" style={{ objectFit: "cover" }} />
                <ProductDescription />
            </div> */}
        </div>
    </>)
}

export default NewOrder;