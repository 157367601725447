import axios from "axios"

// _api({ isToken: false });

export const _api = async ({
    method,
    url,
    data,
    config,
    isToken, // default : true otherwise false
}) => {
    if (!method) method = 'GET';
    if (!url) url = '';
    if (!data) data = {};
    if (!config) config = {};
    if (isToken === undefined) isToken = true;

    if (isToken) {
        if (!config["headers"]) {
            config["headers"] = {}
        }
        config["headers"]["Authorization"] = `Bearer ${window.localStorage.getItem("gms_access_token")}`
    }

    try {
        url = process.env.REACT_APP_API_URL + url;
        if (method == 'GET') {
            let res = await axios.get(url, config);
            return res;
        }
        if (method == 'POST') {
            let res = await axios.post(url, data, config);
            return res;
        }
        if (method == 'PATCH') {
            let res = await axios.patch(url, data, config);
            return res;
        }
        if (method == 'DELETE') {
            let res = await axios.delete(url, config);
            return res;
        }
    } catch (error) {
        console.log({ error })
        return { error, };
    }
}
