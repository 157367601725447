import React from "react";
import {
  FaFacebook,
  FaGithub,
  FaInstagram,
  FaTwitter,
  FaTwitch,
} from "react-icons/fa";

const sections = [
  {
    title: "Information",
    items: ["Home", "About US", "Contact US", "Privacy Policy"],
  },
  {
    title: "Services",
    items: ["Books Printing", "Study Material"],
  },
  {
    title: "Company",
    items: ["About", "Blog", "Jobs", "Press", "Partners"],
  },
  {
    title: "Legal",
    items: ["Claims", "Privacy", "Terms", "Policies", "Conditions"],
  },
];

const items = [
  { name: "Facebook", icon: FaFacebook, link: "https://www.facebook.com/" },
  { name: "Instagram", icon: FaInstagram, link: "https://www.instagram.com/" },
  { name: "Twitter", icon: FaTwitter, link: "https://twitter.com/" },
  { name: "Twitch", icon: FaTwitch, link: "https://www.twitch.tv/" },
  { name: "Github", icon: FaGithub, link: "https://github.com/" },
];

const Footer = () => {
  return (
    <>
      <div className="flex flex-col-reverse container justify-between sm:flex-row text-center px-4 py-4 mx-auto border-t-4 border-[#2D408F]">
        <p className="py-4 text-gray-700 font-semibold">
          {new Date().getFullYear()} <a href="/" className="text-sky-600">Gillson Media Solutions</a>, All rights reserved
        </p>
        <div className="flex justify-between sm:w-[300px] pt-4 text-2xl">
          {items.map((x, index) => {
            return <x.icon key={index} className="text-gray-700" />;
          })}
        </div>
      </div>
    </>
  );
};


export default Footer;