import {
  Button,
  CircularProgress,
  FormControl,
  Stack,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { _api } from "../utilities/api";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import StripeCheckout from "react-stripe-checkout";
import StripeCheckoutForm from "./StripeCheckoutForm";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';


const PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(PUBLIC_KEY);

export const Checkout = ({ }) => {
  const location = useLocation();
  const { sounds } = location.state;
  const publishableKey = "pk_test_51OW22XSB9RANda55lVaKE07bYazopSnxJcK7sEbAA2Jola6RQG3duMTb8ttNSyxxKrohjnKd0SdXlVWrjXovCckq00BOJ1beQm";

  const { id, pType } = useParams(); // id is product Id
  const [productDetails, setProductDetails] = useState({});

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [fd, setFD] = useState({

    b_firstName: "",
    b_lastName: "",
    b_streetAddress: "",
    b_landmark: "",
    b_zipcode: "",
    b_city: "",
    b_country: "",
    b_email: "",
    b_phone: "",

    s_firstName: "",
    s_lastName: "",
    s_streetAddress: "",
    s_landmark: "",
    s_zipcode: "",
    s_city: "",
    s_country: "",
    s_email: "",
    s_phone: "",

    isBothAddressSame: false,
  });

  useEffect(() => {
    _api({
      url: `/${pType}_product/${id}`,
    })
      .then((res) => {
        console.log(res?.data, "-=-product details");
        if (res?.data) {
          setProductDetails(res?.data);
        }
      })
      .catch((_) => {
      });
  }, [])

  const makeSameAddress = () => {
    setFD(state => {
      return {
        ...fd,
        isBothAddressSame: true,
        s_firstName: state.b_firstName,
        s_lastName: state.b_lastName,
        s_streetAddress: state.b_streetAddress,
        s_landmark: state.b_landmark,
        s_zipcode: state.b_zipcode,
        s_city: state.b_city,
        s_country: state.b_country,
        s_email: state.b_email,
        s_phone: state.b_phone,
      }
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFD({
      ...fd,
      [name]: value,
    });
  };

  const handleCreateOrder = async () => {
    setLoading(true);
    let user = window.localStorage.getItem("p_user");
    user = JSON.parse(user);
    const res = await _api({
      method: "POST",
      url: "/order/create",
      data: {
        pId: id,
        pType: pType,
        userId: user?._id,
        currency: "INR",
        sounds,
        ...fd,
      },
    });

    console.log(res.data);
    return res.data;
  };


  return (
    <>
      <div className="container">
        <Elements stripe={stripePromise}>
          <div id="checkout">
            <div className="py-4 flex flex-col items-start justify-start">
              <h1 className="font-bold my-2 text-lg">Where should we send your order ?</h1>
              <h3 className="my-2 text-lg">Enter your Billing address:</h3>
              <FormControl>
                <TextField
                  className="my-2"
                  onChange={handleChange}
                  name="b_firstName"
                  placeholder="First Name"
                />
                <TextField
                  className="my-2"
                  onChange={handleChange}
                  name="b_lastName"
                  placeholder="Last Name"
                />
                <TextField
                  className="my-2"
                  onChange={handleChange}
                  name="b_streetAddress"
                  placeholder="Street Address"
                />
                <TextField
                  className="my-2"
                  onChange={handleChange}
                  name="b_landmark"
                  placeholder="Nearest Place, Landmark (Optional)"
                />
                <div className="my-2">
                  <TextField
                    sx={{ mr: "10px" }}
                    onChange={handleChange}
                    name="b_zipcode"
                    placeholder="Zip Code"
                  />
                  <TextField
                    onChange={handleChange}
                    name="b_city"
                    placeholder="City"
                  />
                </div>
                <TextField
                  className="my-2"
                  onChange={handleChange}
                  name="b_country"
                  placeholder="Country"
                />
                <TextField
                  className="my-2"
                  onChange={handleChange}
                  name="b_email"
                  placeholder="Email"
                />
                <TextField
                  className="my-2"
                  onChange={handleChange}
                  name="b_phone"
                  placeholder="Phone"
                />

                <h3 className="my-2 mt-4 text-lg">Enter your Shipping address:</h3>
                <div>
                  <input type="checkbox" name="" id="dd" className="mr-2" onChange={(e) => {
                    if (e.target.checked) {
                      makeSameAddress()
                    } else {
                      setFD({
                        ...fd,
                        isBothAddressSame: false
                      })
                    }
                  }} />
                  <label htmlFor="dd">My Billing & Shipping address is same</label>
                </div>
                {
                  !fd?.isBothAddressSame && <>


                    <TextField
                      className="my-2"
                      onChange={handleChange}
                      name="s_firstName"
                      placeholder="First Name"
                    />
                    <TextField
                      className="my-2"
                      onChange={handleChange}
                      name="s_lastName"
                      placeholder="Last Name"
                    />
                    <TextField
                      className="my-2"
                      onChange={handleChange}
                      name="s_streetAddress"
                      placeholder="Street Address"
                    />
                    <TextField
                      className="my-2"
                      onChange={handleChange}
                      name="s_landmark"
                      placeholder="Nearest Place, Landmark (Optional)"
                    />
                    <div className="my-2">
                      <TextField
                        sx={{ mr: "10px" }}
                        onChange={handleChange}
                        name="s_zipcode"
                        placeholder="Zip Code"
                      />
                      <TextField
                        onChange={handleChange}
                        name="s_city"
                        placeholder="City"
                      />
                    </div>
                    <TextField
                      className="my-2"
                      onChange={handleChange}
                      name="s_country"
                      placeholder="Country"
                    />
                    <TextField
                      className="my-2"
                      onChange={handleChange}
                      name="s_email"
                      placeholder="Email"
                    />
                    <TextField
                      className="my-2"
                      onChange={handleChange}
                      name="s_phone"
                      placeholder="Phone"
                    />

                  </>
                }
              </FormControl>

              <StripeCheckoutForm createOrder={handleCreateOrder} />


              {/* <button
              className="mt-8 bg-black px-4 py-2 rounded text-white hover:bg-gray-500"
              onClick={() => (loading ? null : handleCreateOrder())}
              variant="contained"
            >
              {loading ? "Processing..." : "Continue to payment"}
            </button> */}
            </div>
            <div className="review"></div>
          </div>
        </Elements>
      </div>
    </>
  );
};
