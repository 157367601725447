import React, { useEffect, useState } from 'react';
import axios from 'axios';
import FormData from 'form-data';
import { Box, Button, CircularProgress, FormControl, LinearProgress, Modal, Select, Stack, Step, StepLabel, Stepper, TextField, Typography } from '@mui/material';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/build/pdf';
import { _api } from '../utilities/api';
import { useNavigate } from 'react-router-dom';
// Set the workerSrc for PDF.js worker
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/4.4.168/pdf.worker.min.js`;
const steps = ['Printing Details', 'Billing Details', 'Shipping Details', 'Payment'];


let stateList = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttarakhand",
    "Uttar Pradesh",
    "West Bengal",
    "Andaman and Nicobar",
    "Chandigarh",
    "Dadra and Nagar",
    "Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry"
]

const OrderForm = () => {
    const [formJSON, setFormJSON] = useState({})
    const [loading, setLoading] = useState(true)
    const [uploadingStatus, setUploadingStatus] = useState(false)
    const [uploadingStatus2, setUploadingStatus2] = useState(false)
    const [progress1, setProgress1] = useState(0)
    const [progress2, setProgress2] = useState(0)

    const [numPages, setNumPages] = useState(0)
    const [courierTotalCharges, setTotalCourierCharges] = useState(0)
    const [totalWeight, setTotalWeight] = useState(0)
    const [fileName, setFileName] = useState(null)
    const [source, setSource] = useState(null);

    const [formData, setFormData] = useState({
        product: '',
        size: '',
        paper: '',
        printColor: '',
        printSides: '',
        binding: '',
        quantity: 1,
        shipping: '',

        orderName: '',
        remark: '',
        qrFile: null,
        printingFile: null,


        // Calculate Cost Information
        cost: 0,
        gst: 0,
        amountPayable: 0,


        b_firstName: "",
        b_lastName: "",
        b_streetAddress: "",
        b_landmark: "",
        b_zipcode: "",
        b_city: "",
        b_country: "",
        b_email: "",
        b_phone: "",
        gst_number: "",

        s_firstName: "",
        s_lastName: "",
        s_streetAddress: "",
        s_landmark: "",
        s_zipcode: "",
        s_city: "",
        s_country: "",
        s_email: "",
        s_phone: "",

        isBothAddressSame: false,
    });

    const [open, setOpen] = useState(false)
    const [activeStep, setActiveStep] = useState(0);
    const [user, setUser] = useState(null);

    const navigate = useNavigate();



    useEffect(() => {
        _api({
            method: "GET",
            url: "/auth/me",
        }).then(response => {
            if (response?.data?.data?.info) {
                let info = JSON.parse(response?.data?.data?.info)
                let address = JSON.parse(info?.address)
                delete info?.address;

                setUser(response?.data?.data)

                setFormData({
                    product: info?.orderDetails?.product,
                    size: info?.orderDetails?.size,
                    paper: info?.orderDetails?.paper,
                    printColor: info?.orderDetails?.printColor,
                    printSides: info?.orderDetails?.printSides,
                    binding: info?.orderDetails?.binding,
                    quantity: parseInt(info?.orderDetails?.quantity),
                    shipping: info?.orderDetails?.shipping,

                    orderName: info?.orderDetails?.orderName,
                    remark: info?.remark,

                    b_firstName: address?.b_firstName,
                    b_lastName: address?.b_lastName,
                    b_streetAddress: address?.b_streetAddress,
                    b_landmark: address?.b_landmark,
                    b_zipcode: address?.b_zipcode,
                    b_city: address?.b_city,
                    b_country: address?.b_country,
                    b_email: address?.b_email,
                    b_phone: address?.b_phone,
                    gst_number: address?.gst_number,

                    s_firstName: address?.s_firstName,
                    s_lastName: address?.s_lastName,
                    s_streetAddress: address?.s_streetAddress,
                    s_landmark: address?.s_landmark,
                    s_zipcode: address?.s_zipcode,
                    s_city: address?.s_city,
                    s_country: address?.s_country,
                    s_email: address?.s_email,
                    s_phone: address?.s_phone,
                });

            } else {
                setUser(response?.data?.data)
            }
        })
    }, [])


    useEffect(() => {
        console.log({ user }, "==user")
        if (!user) return;

        _api({
            method: "GET",
            url: "/settings",
        }).then(response => {
            console.log(response, "---settings");

            let data;
            let isSpecial = Boolean(user?.isSpecial)
            if (response?.data?.data?.settings && response?.data?.data?.settings?.trim() != "" && !isSpecial) {
                data = JSON.parse(response?.data?.data?.settings)
            }
            if (response?.data?.data?.settings && response?.data?.data?.settings?.trim() != "" && isSpecial) {
                data = JSON.parse(response?.data?.data?.specialSettings)
            }
            setFormJSON(data)
            setLoading(false)
        })
    }, [user])


    const getNumPages = () => {

        // if (formData?.printSides === "BOTH SIDES") {
        //     let n = numPages;
        //     if (n % 2 !== 0) {
        //         n += 1;
        //     }

        //     return n / 2;
        // }

        return numPages;
    }


    useEffect(() => {
        const calculatePrintingCost = async () => {
            if (!formData?.printingFile) return;


            let totalPages = await getNoOfPagesOfPdf();
            if (!totalPages) return;

            setNumPages(totalPages);

            if (!formData?.s_city) {
                console.log("shipping city required for pricing, in-case of shipping type is courier or undefined")
                return;
            }

            const isDoubleSided = formData.printSides === "BOTH SIDES";
            const isColored = formData.printColor === "COLOR";
            const sizeDetails = formJSON.size.find(s => s.title === formData.size);
            const paperDetails = formJSON.paper.find(p => p.title === formData.paper);
            // const colorDetails = formJSON.printColor.find(c => c.title === formData.printColor);
            const bindingDetails = formJSON.binding.find(b => b.title === formData.binding);
            const isCourier = ["TRANSPORT", "COURIER"].includes(formData.shipping);

            if (!sizeDetails || !paperDetails || !bindingDetails) {
                return;
            }

            let costPerPage;
            if (!isDoubleSided) { // Single Side
                if (isColored) {
                    costPerPage = parseFloat(formJSON.priceOfSinglePage[`${formData.size}_${formData.paper}`][0])  // Single Color
                } else {
                    costPerPage = parseFloat(formJSON.priceOfSinglePage[`${formData.size}_${formData.paper}`][1])  // Single B&W
                }
            } else {
                // // Half the pages - 1.0
                // if (totalPages % 2 !== 0) {
                //     totalPages += 1;
                // }

                // // Half the pages - 1.1
                // let halfPages = totalPages / 2;
                // totalPages = halfPages;

                if (isColored) {
                    costPerPage = parseFloat(formJSON.priceOfSinglePage[`${formData.size}_${formData.paper}`][2]) // Both Color
                } else {
                    costPerPage = parseFloat(formJSON.priceOfSinglePage[`${formData.size}_${formData.paper}`][3]) // Both B&W
                }
            }


            let totalCost = totalPages * costPerPage * parseFloat(formData.quantity);

            let bindingCost = parseFloat(bindingDetails.bindingCharges) * parseFloat(formData.quantity);
            totalCost += bindingCost;


            let shippingCost = 0;
            if (isCourier) {
                let totalWeight = (totalPages * parseFloat(formJSON.weightOfSinglePage[`${formData.size}_${formData.paper}`]) + parseFloat(bindingDetails.singleBindingWeight)) * parseFloat(formData.quantity) / 1000; // convert to kg
                // here total weight in kg
                const perKgCharges = formJSON.shippingChargesPerKg[formData?.s_city];


                // const shippingDetail = formJSON.shippingCharges.find(sc => totalWeight <= sc.weight);
                // if (shippingDetail) {
                //     shippingCost = parseFloat(shippingDetail.price);
                // }
                shippingCost = Math.ceil(totalWeight) * perKgCharges;
                setTotalCourierCharges(shippingCost);
                setTotalWeight(totalWeight);
                // console.log({ totalWeight, shippingDetail });
            } else {
                setTotalCourierCharges(0);
                setTotalWeight(0);
            }

            let gstAmount = totalCost * parseFloat(formJSON.gstPrice);
            let amountPayable = totalCost + gstAmount + shippingCost;

            setFormData(prevFormData => ({
                ...prevFormData,
                cost: totalCost.toFixed(2),
                gst: gstAmount.toFixed(2),
                amountPayable: amountPayable.toFixed(2)
            }));
        };


        calculatePrintingCost();
    }, [JSON.stringify(formData), formData?.printingFile, formData?.s_city]);

    const handleNext = () => {
        const isCourier = ["TRANSPORT", "COURIER"].includes(formData.shipping);

        setActiveStep((prevActiveStep) => {
            if (prevActiveStep == 2 && !Boolean(formData?.s_city) && isCourier) {
                alert("Shipping city is required")
                return prevActiveStep;
            }
            return prevActiveStep + 1
        });
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const getNoOfPagesOfPdf = async () => {

        if (numPages !== 0) return numPages;

        setUploadingStatus(true)
        setProgress1(0)
        let fd = new FormData()

        setFileName(formData?.printingFile?.name)

        fd.append("pdf", formData?.printingFile)


        const cancelSource = axios.CancelToken.source(); // Create a new cancel token source
        setSource(cancelSource); // Store it in state so we can use it later

        let url = process.env.REACT_APP_API_URL + "/pdf-info";
        try {
            let response = await axios.post(url, fd, {
                cancelToken: cancelSource.token,
                headers: {
                    'content-type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent) => {
                    console.log({ progressEvent });
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress1(progress)
                    console.log(`Upload Progress: ${progress}%`);
                },
            })

            setUploadingStatus(false)
            setProgress1(100)
            return response.data.numPages;

        } catch (err) {
            if (axios.isCancel(err)) {
                console.log('Request canceled:', err.message);
            } else {
                console.log(err, "--err");
            }

            return false;
        }
    }


    // Clear File
    const clearFileData = () => {
        setFormData({
            ...formData,
            "printingFile": null
        });
        setFileName(null)
        setNumPages(0)
        setUploadingStatus(false)
    }

    // Cancel Request
    const cancelRequest = () => {
        if (source) {
            source.cancel('Request canceled by the user.');
            setSource(null); // Reset the source after canceling
        }
        clearFileData()
    };

    const removeFile = () => {
        clearFileData()
    };



    const handleChange = (e) => {
        const { name, value, files } = e.target;

        if (name == "printingFile") {
            setNumPages(0)
        }

        setFormData({
            ...formData,
            [name]: files ? files[0] : value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setUploadingStatus2(true)
        setProgress2(0)
        let data = new FormData();
        data.append('orderName', formData.orderName);
        data.append('product', formData.product);
        data.append('size', formData.size);
        data.append('quantity', formData.quantity);
        data.append('cost', formData.cost);
        data.append('gst', formData.gst);
        data.append('subTotal', formData.amountPayable);
        data.append('remark', formData.remark);
        data.append('qrFile', formData.qrFile);
        data.append('printingFile', formData.printingFile);
        data.append('paper', formData.paper);
        data.append('printColor', formData.printColor);
        data.append('printSides', formData.printSides);
        data.append('binding', formData.binding);
        data.append('shipping', formData.shipping);

        data.append('b_firstName', formData.b_firstName);
        data.append('b_lastName', formData.b_lastName);
        data.append('b_streetAddress', formData.b_streetAddress);
        data.append('b_landmark', formData.b_landmark);
        data.append('b_zipcode', formData.b_zipcode);
        data.append('b_city', formData.b_city);
        data.append('b_country', formData.b_country);
        data.append('b_email', formData.b_email);
        data.append('b_phone', formData.b_phone);
        data.append('gst_number', formData.gst_number);

        data.append('s_firstName', formData.s_firstName);
        data.append('s_lastName', formData.s_lastName);
        data.append('s_streetAddress', formData.s_streetAddress);
        data.append('s_landmark', formData.s_landmark);
        data.append('s_zipcode', formData.s_zipcode);
        data.append('s_city', formData.s_city);
        data.append('s_country', formData.s_country);
        data.append('s_email', formData.s_email);
        data.append('s_phone', formData.s_phone);

        let url = process.env.REACT_APP_API_URL + "/orders/create";

        let res = await axios.post(url, data, {
            headers: {
                'content-type': 'multipart/form-data',
                "Authorization": `Bearer ${window.localStorage.getItem("gms_access_token")}`
            },
            onUploadProgress: (progressEvent) => {
                console.log({ progressEvent });
                const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setProgress2(progress)
                console.log(`Upload Progress: ${progress}%`);
            },
        }).then(({ error, data }) => {
            if (error) {
                alert(error.response.data.message);
                return;
            }
            setProgress2(100)
            alert("Order Placed Successfully");
            navigate("/orders");
        }).finally(() => {
            setUploadingStatus2(false)
        });
    };

    const _qrModal = () => {
        return (
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 500,
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Pay Here
                    </Typography>
                    <p><i>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Qui accusantium quis et reprehenderit iste quod animi ad aspernatur error laborum.</i></p>
                    <div className='flex-col items-center flex'>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/d/d0/QR_code_for_mobile_English_Wikipedia.svg"
                            className='w-[300px]' />
                        <input type="file" name="qrFile" onChange={handleChange} hidden id="qrrr" />
                        {formData?.qrFile?.name || ""}
                        <label className='bg-blue-400 px-4 py-2 rounded hover:bg-blue-500 cursor-pointer' htmlFor="qrrr">Upload Screenshot of Payment <b>{formData?.amountPayable}/- Rupees</b></label>
                        <Button sx={{ mx: 1, bgcolor: "green" }} variant="contained" onClick={(e) => handleSubmit(e)}>
                            {!uploadingStatus2 ? "Place Order" : `Please wait... ${progress2}%`}
                        </Button>
                    </div>
                </Box>
            </Modal>
        );
    };


    const makeSameAddress = () => {
        setFormData(state => ({
            ...state,
            isBothAddressSame: true,
            s_firstName: state.b_firstName,
            s_lastName: state.b_lastName,
            s_streetAddress: state.b_streetAddress,
            s_landmark: state.b_landmark,
            s_zipcode: state.b_zipcode,
            s_city: state.b_city,
            s_country: state.b_country,
            s_email: state.b_email,
            s_phone: state.b_phone,
        }));
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <CircularProgress />
            </div>
        )
    }

    return (
        <div className="p-2 mb-5 sm:mb-0 sm:p-6 w-full sm:w-3/4 bg-white rounded-lg shadow-md border border-black">
            {/* {_qrModal()} */}
            <div className='w-full mx-auto sm:mt-8'>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>
                                    <span className="hidden sm:block">{label}</span>
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <React.Fragment>
                    <div className="h-[65vh] overflow-auto my-[20px] sm:my-8 px-4">
                        {activeStep == 0 && <>
                            <div className="mb-4 sm:mt-8">
                                <label htmlFor="orderName" className="block text-sm font-medium text-gray-700">Order Name</label>
                                <input
                                    type="text"
                                    name="orderName"
                                    id="orderName"
                                    value={formData.orderName}
                                    onChange={handleChange}
                                    className="mt-1 p-2 w-full border rounded-md"
                                    placeholder="यहां अपने कस्टमर का नाम टाइप करें जिससे ऑर्डर को ट्रैक करना आसान हो..."
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="product" className="block text-sm font-medium text-gray-700">Select Product</label>
                                <select
                                    name="product"
                                    id="product"
                                    value={formData.product}
                                    onChange={handleChange}
                                    className="mt-1 p-2 w-full border rounded-md"
                                >
                                    <option value="">-- SELECT --</option>
                                    {
                                        formJSON.products?.map(item => {
                                            return <option value={item}>{item}</option>
                                        })
                                    }
                                    {/* Add more options as needed */}
                                </select>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="size" className="block text-sm font-medium text-gray-700">Size</label>
                                <select
                                    name="size"
                                    id="size"
                                    value={formData.size}
                                    onChange={handleChange}
                                    className="mt-1 p-2 w-full border rounded-md"
                                >
                                    <option value="">-- SELECT --</option>
                                    {
                                        formJSON.size?.map(item => {
                                            return <option value={item.title}>{item.title}</option>
                                        })
                                    }
                                    {/* Add size options */}
                                </select>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="paper" className="block text-sm font-medium text-gray-700">Paper</label>
                                <select
                                    name="paper"
                                    id="paper"
                                    value={formData.paper}
                                    onChange={handleChange}
                                    className="mt-1 p-2 w-full border rounded-md"
                                >
                                    <option value="">-- SELECT --</option>
                                    {
                                        formJSON.paper?.map(item => {
                                            return <option value={item.title}>{item.title}</option>
                                        })
                                    }
                                    {/* Add paper options */}
                                </select>
                            </div>
                            <div className="flex flex-row">
                                <div className="mb-4 mx-2 w-1/2">
                                    <label htmlFor="printColor" className="block text-sm font-medium text-gray-700">Printing Color</label>
                                    <select
                                        name="printColor"
                                        id="printColor"
                                        value={formData.printColor}
                                        onChange={handleChange}
                                        className="mt-1 p-2 w-full border rounded-md"
                                    >
                                        <option value="">-- SELECT --</option>
                                        {
                                            formJSON.printColor?.map(item => {
                                                return <option value={item.title}>{item.title}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="mb-4 mx-2 w-1/2">
                                    <label htmlFor="printSides" className="block text-sm font-medium text-gray-700">Printing Side</label>
                                    <select
                                        name="printSides"
                                        id="printSides"
                                        value={formData.printSides}
                                        onChange={handleChange}
                                        className="mt-1 p-2 w-full border rounded-md"
                                    >
                                        <option value="">-- SELECT --</option>
                                        {
                                            formJSON.printSides?.map(item => {
                                                return <option value={item}>{item}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="binding" className="block text-sm font-medium text-gray-700">Binding</label>
                                <select
                                    name="binding"
                                    id="binding"
                                    value={formData.binding}
                                    onChange={handleChange}
                                    className="mt-1 p-2 w-full border rounded-md"
                                >
                                    <option value="">-- SELECT --</option>
                                    {
                                        formJSON.binding?.map(item => {
                                            return <option value={item.title}>{item.title}</option>
                                        })
                                    }
                                    {/* Add binding options */}
                                </select>
                            </div>
                            {/* <div className="mb-4">
                                <label htmlFor="shipping" className="block text-sm font-medium text-gray-700">Shipping</label>
                                <select
                                    name="shipping"
                                    id="shipping"
                                    value={formData.shipping}
                                    onChange={handleChange}
                                    className="mt-1 p-2 w-full border rounded-md"
                                >
                                    <option value="">-- SELECT --</option>
                                    {
                                        formJSON.shipping?.map(item => {
                                            return <option value={item}>{item}</option>
                                        })
                                    }
                                </select>
                            </div> */}
                            <div className="mb-4">
                                <label htmlFor="quantity" className="block text-sm font-medium text-gray-700">Quantity</label>
                                <input
                                    min={1}
                                    type="number"
                                    name="quantity"
                                    id="quantity"
                                    value={formData.quantity}
                                    onChange={handleChange}
                                    className="mt-1 p-2 w-full border rounded-md"
                                    required
                                />
                            </div>
                            {/* <div className="mb-4">
                <label htmlFor="qrFile" className="block text-sm font-medium text-gray-700">QR File</label>
                <input
                    type="file"
                    name="qrFile"
                    id="qrFile"
                    onChange={handleChange}
                    className="mt-1 p-2 w-full border rounded-md"
                    required
                />
            </div> */}
                            {
                                fileName ? <div className='flex flex-col w-full'>
                                    <LinearProgress variant="determinate" value={progress1} />
                                    <div className="w-full h-11 bg-gray-300 mb-4 flex justify-between items-center p-2 gap-3">
                                        <div className="flex items-center gap-3">
                                            <InsertDriveFileIcon />
                                            {fileName}
                                        </div>
                                        <div className='flex items-center gap-2'>
                                            {progress1 == 100 ?
                                                <div className='flex items-center gap-3'>
                                                    <span className='text-gray-500 font-semibold'>{Boolean(getNumPages()) ? `${getNumPages()} Pages` : <div className=''><CircularProgress size={"18px"} className='mt-1' color='success' /></div>} </span>
                                                    <HighlightOffIcon onClick={removeFile} className='hover:text-red-500 cursor-pointer' />
                                                </div> :
                                                <div className='flex items-center gap-3'>
                                                    <Button className='flex items-center gap-3' onClick={cancelRequest}>
                                                        <div className="text-red-500 hover:text-red-700">
                                                            Cancel
                                                        </div>
                                                    </Button>
                                                    <span className='text-sm font-semibold'>{progress1}%</span>
                                                </div>}

                                        </div>
                                    </div>
                                </div> : <><div className="mb-4">
                                    <label htmlFor="printingFile" className="block text-sm font-medium text-gray-700">Printing File</label>
                                    <input
                                        type="file"
                                        accept=".pdf"
                                        name="printingFile"
                                        id="printingFile"
                                        onChange={handleChange}
                                        className="mt-1 p-2 w-full border rounded-md"
                                        required
                                    />
                                    <small><b>Note : </b>{uploadingStatus ? <i>Uploading... {progress1}%</i> : <i>Upload PDF File</i>}</small>
                                </div></>
                            }

                            {/* <div className="mb-4 flex flex-row items-center border-b-2 pb-2">
                                <label htmlFor="cost" className="block m-0 p-0 text-sm  mr-auto font-bold text-gray-700">Cost</label>
                                <span>Rs {formData?.cost}/-</span>
                            </div>
                            {
                                parseFloat(totalWeight) > 0 &&
                                <div className="mb-4 flex flex-row items-center border-b-2 pb-2">
                                    <label htmlFor="cost" className="block m-0 p-0 text-sm  mr-auto font-bold text-gray-700">Courier Charges</label>
                                    <span>{courierTotalCharges}/- for {totalWeight} KG</span>
                                </div>
                            }
                            <div className="mb-4 flex flex-row items-center border-b-2 pb-2">
                                <label htmlFor="cost" className="block m-0 p-0 text-sm  mr-auto font-bold text-gray-700">GST (18.00%)</label>
                                <span>Rs {formData?.gst}/-</span>
                            </div>
                            <div className="mb-4 flex flex-row items-center border-b-2 pb-2">
                                <label htmlFor="cost" className="block m-0 p-0 text-sm mr-auto font-bold text-gray-700">Amount Payable</label>
                                <span className='font-semibold'>Rs {formData?.amountPayable}/-</span>
                            </div> */}
                            <div className="mb-4">
                                <label htmlFor="remark" className="block text-sm font-medium text-gray-700">Special Remark (Optional)</label>
                                <textarea
                                    name="remark"
                                    id="remark"
                                    value={formData.remark}
                                    onChange={handleChange}
                                    className="mt-1 p-2 w-full border rounded-md"
                                />
                            </div>
                            {/* <button type="button" onClick={() => setOpen(true)} className="w-full py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700">Add Order</button> */}
                        </>}

                        {activeStep === 1 && (
                            <Box className="flex flex-col justify-center w-full items-center">
                                <h1 className="font-bold my-2 mt-4 text-lg">What is Your Billing Address?</h1>
                                <FormControl className="w-full">
                                    <div className="flex flex-row">
                                        <div className="mr-1 w-1/2">
                                            <TextField
                                                className="my-2 w-full"
                                                onChange={handleChange}
                                                name="b_firstName"
                                                value={formData?.b_firstName}
                                                placeholder="First Name"
                                            />
                                        </div>
                                        <div className="mr-1 w-1/2">
                                            <TextField
                                                className="my-2 w-full"
                                                onChange={handleChange}
                                                name="b_lastName"
                                                value={formData?.b_lastName}
                                                placeholder="Last Name"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-row">
                                        <div className="ml-1 w-1/2">
                                            <TextField
                                                className="my-2 w-full"
                                                onChange={handleChange}
                                                name="b_email"
                                                value={formData?.b_email}
                                                placeholder="Email"
                                            />
                                        </div>
                                        <div className="ml-1 w-1/2">
                                            <TextField
                                                className="my-2 w-full"
                                                onChange={handleChange}
                                                name="b_phone"
                                                value={formData?.b_phone}
                                                placeholder="Phone"
                                            />
                                        </div>
                                    </div>
                                    <TextField
                                        className="my-2"
                                        onChange={handleChange}
                                        name="b_streetAddress"
                                        value={formData?.b_streetAddress}
                                        placeholder="Street Address"
                                    />
                                    <TextField
                                        className="my-2"
                                        onChange={handleChange}
                                        name="b_landmark"
                                        value={formData?.b_landmark}
                                        placeholder="Nearest Place, Landmark (Optional)"
                                    />
                                    <div className="flex flex-row w-full">
                                        <div className="mr-1 w-1/3">
                                            <select
                                                name='b_city'
                                                onChange={handleChange}
                                                value={formData?.b_city}
                                                className='w-full h-14 border-2 border-gray-300 rounded bg-white text-black'
                                                placeholder='State'>
                                                <option value="">Select State</option>
                                                {
                                                    stateList?.map((state, index) => {
                                                        return <option value={state}>{state}</option>
                                                    })
                                                }
                                            </select>
                                            {/* <TextField
                                                className="w-full"
                                                onChange={handleChange}
                                                name="b_city"
                                                value={formData?.b_city}
                                                placeholder="City"
                                            /> */}
                                        </div>
                                        <div className="mr-1 w-1/3">
                                            <TextField
                                                className="w-full"
                                                onChange={handleChange}
                                                name="b_country"
                                                value={formData?.b_country}
                                                placeholder="Country"
                                            />
                                        </div>
                                        <div className="mr-1 w-1/3">
                                            <TextField
                                                className="w-full"
                                                onChange={handleChange}
                                                name="b_zipcode"
                                                value={formData?.b_zipcode}
                                                placeholder="Zip Code"
                                            />
                                        </div>
                                    </div>

                                    <div className="mr-1 w-full">
                                        <Typography my={2} fontWeight={400}>Do you have GST number ? <small>(Optional)</small></Typography>
                                        <TextField
                                            className="w-full"
                                            onChange={handleChange}
                                            name="gst_number"
                                            value={formData?.gst_number}
                                            placeholder="GST Number"
                                        />
                                    </div>
                                </FormControl>
                            </Box>
                        )}

                        {activeStep === 2 && (
                            <>

                                <div className="mb-4">
                                    <label htmlFor="shipping" className="block text-sm font-medium text-gray-700">Shipping</label>
                                    <select
                                        name="shipping"
                                        id="shipping"
                                        value={formData.shipping}
                                        onChange={handleChange}
                                        className="mt-1 p-2 w-full border rounded-md"
                                    >
                                        <option value="">-- SELECT --</option>
                                        {
                                            formJSON.shipping?.map(item => {
                                                return <option value={item}>{item}</option>
                                            })
                                        }
                                    </select>
                                </div>

                                {
                                    ["COURIER", "TRANSPORT"].includes(formData?.shipping) && <Box className="flex flex-col justify-center w-full items-center">
                                        <h1 className="font-bold my-2 mt-4 text-lg">What is Your Shipping Address</h1>
                                        <FormControl className="w-full">
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    name="isBothAddressSame"
                                                    id="sameAddress"
                                                    className="mr-2"
                                                    checked={formData.isBothAddressSame}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            makeSameAddress();
                                                        } else {
                                                            setFormData({
                                                                ...formData,
                                                                isBothAddressSame: false,
                                                                s_firstName: "",
                                                                s_lastName: "",
                                                                s_streetAddress: "",
                                                                s_landmark: "",
                                                                s_zipcode: "",
                                                                s_city: "",
                                                                s_country: "",
                                                                s_email: "",
                                                                s_phone: "",
                                                            });
                                                        }
                                                    }}
                                                />
                                                <label htmlFor="sameAddress">My Billing & Shipping address is same</label>
                                            </div>

                                            <div className="flex flex-row">
                                                <div className="mr-1 w-1/2">
                                                    <TextField
                                                        className="my-2 w-full"
                                                        onChange={handleChange}
                                                        name="s_firstName"
                                                        value={formData?.s_firstName}
                                                        placeholder="First Name"
                                                    />
                                                </div>
                                                <div className="mr-1 w-1/2">
                                                    <TextField
                                                        className="my-2 w-full"
                                                        onChange={handleChange}
                                                        name="s_lastName"
                                                        value={formData?.s_lastName}
                                                        placeholder="Last Name"
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex flex-row">
                                                <div className="ml-1 w-1/2">
                                                    <TextField
                                                        className="my-2 w-full"
                                                        onChange={handleChange}
                                                        name="s_email"
                                                        value={formData?.s_email}
                                                        placeholder="Email"
                                                    />
                                                </div>
                                                <div className="ml-1 w-1/2">
                                                    <TextField
                                                        className="my-2 w-full"
                                                        onChange={handleChange}
                                                        name="s_phone"
                                                        value={formData?.s_phone}
                                                        placeholder="Phone"
                                                    />
                                                </div>
                                            </div>
                                            <TextField
                                                className="my-2"
                                                onChange={handleChange}
                                                name="s_streetAddress"
                                                value={formData?.s_streetAddress}
                                                placeholder="Street Address"
                                            />
                                            <TextField
                                                className="my-2"
                                                onChange={handleChange}
                                                name="s_landmark"
                                                value={formData?.s_landmark}
                                                placeholder="Nearest Place, Landmark (Optional)"
                                            />
                                            <div className="flex flex-row w-full">
                                                <div className="mr-1 w-1/3">
                                                    <select
                                                        name='s_city'
                                                        onChange={handleChange}
                                                        value={formData?.s_city}
                                                        className='w-full h-14 border-2 border-gray-300 rounded bg-white text-black'
                                                        placeholder='State'>
                                                        <option value="">Select State</option>
                                                        {
                                                            stateList?.map((state, index) => {
                                                                return <option value={state}>{state}</option>
                                                            })
                                                        }
                                                    </select>
                                                    {/* <TextField
                                                        className="w-full"
                                                        onChange={handleChange}
                                                        name="s_city"
                                                        value={formData?.s_city}
                                                        placeholder="City"
                                                    /> */}
                                                </div>
                                                <div className="mr-1 w-1/3">
                                                    <TextField
                                                        className="w-full"
                                                        onChange={handleChange}
                                                        name="s_country"
                                                        value={formData?.s_country}
                                                        placeholder="Country"
                                                    />
                                                </div>
                                                <div className="mr-1 w-1/3">
                                                    <TextField
                                                        className="w-full"
                                                        onChange={handleChange}
                                                        name="s_zipcode"
                                                        value={formData?.s_zipcode}
                                                        placeholder="Zip Code"
                                                    />
                                                </div>
                                            </div>
                                        </FormControl>
                                    </Box>}

                                {
                                    ["BY HAND", "SELF COLLECTION"].includes(formData?.shipping) && <Box className="flex flex-col">
                                        <h1 className="font-bold my-2 mt-4 text-lg">You can pick your order from this address</h1>
                                        <address>
                                            <pre>
                                                GILLSON MEDIA SOLUTIONS <br />
                                                Scf 116A, Phase-5, Industrial Area, Mohali <br />
                                                0172-4031356, 9872005082 <br />
                                            </pre>
                                        </address>
                                    </Box>
                                }
                            </>


                        )}

                        {activeStep == 3 && <div className='flex w-full flex-col sm:flex-row-reverse py-2 sm:px-4 sm:py-8'>
                            <div className="right flex flex-col w-full sm:w-1/2 sm:p-4">
                                <div className="mb-4 flex flex-row items-center border-b-2 pb-2">
                                    <label htmlFor="cost" className="block m-0 p-0 text-sm  mr-auto font-bold text-gray-700">Cost</label>
                                    <span>Rs {formData?.cost}/-</span>
                                </div>
                                {
                                    parseFloat(totalWeight) > 0 &&
                                    <div className="mb-4 flex flex-row items-center border-b-2 pb-2">
                                        <label htmlFor="cost" className="block m-0 p-0 text-sm  mr-auto font-bold text-gray-700">Courier Charges</label>
                                        <span>{courierTotalCharges}/- for {totalWeight} KG</span>
                                    </div>
                                }
                                <div className="mb-4 flex flex-row items-center border-b-2 pb-2">
                                    <label htmlFor="cost" className="block m-0 p-0 text-sm  mr-auto font-bold text-gray-700">GST (18.00%)</label>
                                    <span>Rs {formData?.gst}/-</span>
                                </div>
                                <div className="mb-4 flex flex-row items-center border-b-2 pb-2">
                                    <label htmlFor="cost" className="block m-0 p-0 text-sm mr-auto font-bold text-gray-700">Amount Payable</label>
                                    <span className='font-semibold'>Rs {formData?.amountPayable}/-</span>
                                </div>
                            </div>
                            <div className="left flex flex-col w-full sm:w-1/2 sm:p-4">
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Note*
                                </Typography>
                                <p className='max-w-[500px] text-sm'><i>You need to upload the screenshot of Payment including payment ID or Transaction Id, Payment Amount, Status etc details.</i></p>
                                <div className='flex flex-col'>
                                    <img src="/images/qr.jpeg" className='w-[300px]'/>
                                    <input type="file" name="qrFile" onChange={handleChange} hidden id="qrrr" />
                                    {formData?.qrFile?.name || ""}
                                    <label className='bg-blue-400 px-4 py-2 rounded hover:bg-blue-500 cursor-pointer text-center w-full sm:w-[300px]' htmlFor="qrrr">
                                        Click Here to Upload Screenshot of <br /> Payment <b>{formData?.amountPayable}/- Rupees</b>
                                    </label>
                                </div>
                            </div>
                        </div>}
                    </div>

                    {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {
                            activeStep !== steps.length - 1 ?
                                <Button onClick={handleNext} disabled={activeStep === steps.length}>Next</Button> :
                                <Button sx={{ mx: 1, bgcolor: "green" }} variant="contained" onClick={(e) => handleSubmit(e)}>
                                    {!uploadingStatus2 ? "Place Order" : `Please wait... ${progress2}%`}
                                </Button>
                        }
                    </Box>
                </React.Fragment>
            </div>
        </div >
    );
};

export default OrderForm;
