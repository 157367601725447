import React, { useState } from 'react';
import styled from 'styled-components';
import { _api } from '../utilities/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const setNestedValue = (obj, path, value) => {
    const keys = path.split('.');
    const lastKey = keys.pop();
    const lastObj = keys.reduce((obj, key) =>
        obj[key] = obj[key] || {},
        obj);
    lastObj[lastKey] = value;
};

const getNestedValue = (obj, path, index = null) => {
    const keys = path.split('.');
    if (index != null) {
        const fields = obj[keys[0]];
        return fields[index][keys[1]];
    }
    const lastKey = keys.pop();
    const lastObj = keys.reduce((obj, key) =>
        obj[key] = obj[key] || {},
        obj);
    return lastObj[lastKey];
};

const getImageUrl = (filename) => `${process.env.REACT_APP_SERVER_URL}/uploads/${filename}`;

const FormContainer = styled.div`
    margin: 20px;
`;

const FormSection = styled.div`
    margin-top: 50px;
`;

const SectionTitle = styled.h3`
    margin-top: 20px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Label = styled.label`
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
`;

const Input = styled.input`
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
`;

const FileInputContainer = styled.div`
    margin-top: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 150px;
    height: 150px;
`;

const Image = styled.img`
    border: 1px solid black;
    object-fit: cover;
    width: 100%;
    height: 100%;
    cursor: pointer;
`;

const FileInputActions = styled.div`
    width: 100%;
    display: flex;
`;

const FileInputLabel = styled.label`
    background: ${props => props.background || 'red'};
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 50%;
    cursor: pointer;
`;

const RemoveButton = styled.button`
    background: red;
    color: white;
    border: none;
    padding: 4px 4px 1px;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 4px;
    position: absolute;
    top: 10px;
    right:10px;
`;

const SubmitButton = styled.button`
    background: blue;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-top: 20px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 16px;
`;

const HomeSettingsForm = ({ initialData }) => {
    const [formData, setFormData] = useState(initialData);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = { ...formData };
        setNestedValue(updatedFormData, name, value);
        setFormData(updatedFormData);
    };

    const handleFileChange = async (e, fieldName) => {
        const file = e.target.files[0];
        if (file) {
            const fileUrl = await uploadFile(file);
            const updatedFormData = { ...formData };
            setNestedValue(updatedFormData, fieldName, fileUrl);
            setFormData(updatedFormData);
        }
    };

    const handleArrayChange = (index, e, fieldName) => {
        const newArray = [...formData[fieldName]];
        newArray[index][e.target.name] = e.target.value;
        setFormData({
            ...formData,
            [fieldName]: newArray,
        });
    };

    const handleArrayFileChange = async (index, e, fieldName) => {
        const file = e.target.files[0];
        if (file) {
            const fileUrl = await uploadFile(file);
            const newArray = [...formData[fieldName]];
            newArray[index][e.target.name] = fileUrl;
            setFormData({
                ...formData,
                [fieldName]: newArray,
            });
        }
    };

    const uploadFileAsync = async (e, cb) => {
        const file = e.target.files[0];
        if (file) {
            const fileUrl = await uploadFile(file);
            cb(fileUrl);
        }
    };

    const addArrayField = (fieldName, newFieldTemplate) => {
        setFormData({
            ...formData,
            [fieldName]: [...formData[fieldName], newFieldTemplate],
        });
    };

    const removeArrayField = (fieldName, index) => {
        const newArray = formData[fieldName].filter((_, i) => i !== index);
        setFormData({
            ...formData,
            [fieldName]: newArray,
        });
    };

    const handleRemoveImage = (fieldName) => {
        const updatedFormData = { ...formData };
        setNestedValue(updatedFormData, fieldName, '');
        setFormData(updatedFormData);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await _updateSettings(formData);
    };

    const _updateSettings = async (dataa) => {
        const { error, data } = await _api({
            method: "PATCH",
            url: "/home_settings",
            data: dataa,
        });

        if (error) {
            toast.error(error?.response?.data?.message);
        } else {
            toast.success("Update Successfully");
        }
    };

    const uploadFile = async (file) => {
        let fddd = new FormData();
        fddd.append("file", file);

        const { error, data } = await _api({
            method: "POST",
            url: `/file`,
            data: fddd,
        });
        if (error) {
            toast.error(error?.response?.data?.message);
        } else {
            return data?.filename;
        }
    };

    const renderFileInput = (fieldName, section, index = null) => {
        const handleRemoveClick = (e) => {
            e.preventDefault(); // Prevents the default action of form submission
            handleRemoveImage(fieldName);
        };

        const handleImageClick = (e) => {
            // Prevent the event from bubbling up to parent elements
            e.stopPropagation();
            // Handle any specific action related to clicking the image, if needed
            // For example, opening a modal or enlarging the image
        };

        let nestedValue = getNestedValue(formData, section ? `${section}.${fieldName}` : fieldName, index);

        if (nestedValue) {
            return (
                <FileInputContainer>
                    <Image
                        src={getImageUrl(nestedValue)}
                        alt={fieldName}
                        onClick={handleImageClick} // Add onClick handler for the image
                    />
                    <FileInputActions>
                        <FileInputLabel background="red" onClick={handleRemoveClick}>
                            Remove
                        </FileInputLabel>
                        <FileInputLabel background="blue">
                            Change
                            <input
                                type="file"
                                style={{ display: "none" }}
                                name={fieldName}
                                onChange={(e) => index !== null ? handleArrayFileChange(index, e, section) : handleFileChange(e, fieldName)}
                            />
                        </FileInputLabel>
                    </FileInputActions>
                </FileInputContainer>
            );
        } else {
            return (
                <div>
                    <input type="file" name={fieldName} onChange={(e) => index !== null ? handleArrayFileChange(index, e, section) : handleFileChange(e, fieldName)} />
                </div>
            );
        }
    };

    return (
        <FormContainer>
            <ToastContainer />
            <form onSubmit={handleSubmit}>
                <div>
                    <Label htmlFor="">Logo</Label>
                    {renderFileInput('logo')}
                </div>
                <FormSection>
                    <SectionTitle>Hero Area</SectionTitle>
                    <div style={{ display: "flex" }}>
                        <Label style={{ marginRight: "10px" }}>
                            Heading:
                            <Input type="text" name="heroArea.heading" value={formData.heroArea.heading} onChange={handleChange} />
                        </Label>
                        <Label style={{ marginRight: "10px" }}>
                            Subheading:
                            <Input type="text" name="heroArea.subheading" value={formData.heroArea.subheading} onChange={handleChange} />
                        </Label>
                        <Label style={{ marginRight: "10px" }}>
                            Button Link:
                            <Input type="text" name="heroArea.buttonLink" value={formData.heroArea.buttonLink} onChange={handleChange} />
                        </Label>
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                        <Label>Media File:</Label>
                        {renderFileInput('heroArea.mediaFile')}
                    </div>
                </FormSection>
                <FormSection>
                    <SectionTitle>
                        <span>Features</span>
                        <Button type="button" onClick={() => addArrayField('features', { heading: '', subheading: '', mediaFile: '' })}>
                            Add Feature
                        </Button>
                    </SectionTitle>
                    {formData.features.map((feature, index) => (
                        <div style={{ position: "relative", margin: "10px", borderRadius: "10px", padding: "50px 10px 10px", paddingBottom: "20px", border: "1px solid black" }} key={index}>
                            <RemoveButton type="button" onClick={() => removeArrayField('features', index)}>
                                <DeleteIcon />
                            </RemoveButton>
                            <div style={{ display: "flex" }}>
                                <Label style={{ marginRight: "20px" }}>
                                    Heading:
                                    <Input type="text" name="heading" value={feature.heading} onChange={(e) => handleArrayChange(index, e, 'features')} />
                                </Label>
                                <Label>
                                    Subheading:
                                    <Input type="text" name="subheading" value={feature.subheading} onChange={(e) => handleArrayChange(index, e, 'features')} />
                                </Label>
                            </div>
                            <div>
                                <Label>Media File:</Label>
                                {renderFileInput(`mediaFile`, 'features', index)}
                            </div>
                        </div>
                    ))}

                </FormSection>
                <FormSection>
                    <SectionTitle>
                        <span>Gallery</span>
                        <Button
                            onClick={(e) => { e.stopPropagation(); }}>
                            <label htmlFor="gallery-file-input">
                                Add Gallery Item
                            </label>
                        </Button>
                        <input
                            type="file"
                            style={{ display: "none" }}
                            name="gallery-file-input"
                            onChange={(e) => uploadFileAsync(e, (url) => {
                                setFormData({
                                    ...formData,
                                    Gallery: [...formData.Gallery, { mediaFile: url }]
                                });
                            })}
                            id="gallery-file-input"
                        />
                    </SectionTitle>
                    <Stack direction={"row"} flexWrap={"wrap"}>
                        {formData.Gallery.map((item, index) => (
                            <div key={index} style={{ marginRight: "10px", marginBottom: "10px", position: "relative" }}>
                                <div style={{ width: "100px", height: "100px", margin: "30px" }}>
                                    <Image src={getImageUrl(item?.mediaFile)} />
                                </div>
                                <RemoveButton type="button" onClick={() => removeArrayField('Gallery', index)}>Remove</RemoveButton>
                            </div>
                        ))}
                    </Stack>

                </FormSection>
                {/* <FormSection>
                    <SectionTitle>
                        <span style={{}}>Customers Review Social</span>
                        <Button type="button" onClick={() => addArrayField('customersReviewSocail', { socialType: '', socialUrl: '' })}>
                            Add Customer Review Social
                        </Button>
                    </SectionTitle>

                    {formData.customersReviewSocail.map((review, index) => (
                        <div key={index} style={{ display: "flex", position: "relative" }}>
                            <div style={{ marginRight: "50px" }}>
                                <Label>
                                    Social Type
                                </Label>
                                <select name="socialType" value={review.socialType} onChange={(e) => handleArrayChange(index, e, 'customersReviewSocail')}>
                                    <option value="INSTAGRAM">Instagram</option>
                                    <option value="FACEBOOK">Facebook</option>
                                    <option value="TWITTER">Twitter</option>
                                    <option value="YOUTUBE">YouTube</option>
                                </select>
                            </div>
                            <Label>
                                Social URL:
                                <Input type="text" name="socialUrl" value={review.socialUrl} onChange={(e) => handleArrayChange(index, e, 'customersReviewSocail')} />
                            </Label>
                            <RemoveButton type="button" onClick={() => removeArrayField('customersReviewSocail', index)}>Remove</RemoveButton>
                        </div>
                    ))}
                </FormSection> */}
                <FormSection>
                    <SectionTitle>Last Hero Section</SectionTitle>
                    <div style={{ display: "flex" }}>
                        <Label style={{ marginRight: "10px" }}>
                            Heading:
                            <Input type="text" name="lastHeroSection.heading" value={formData.lastHeroSection.heading} onChange={handleChange} />
                        </Label>
                        <Label style={{ marginRight: "10px" }}>
                            Highlight Text:
                            <Input type="text" name="lastHeroSection.highlightText" value={formData.lastHeroSection.highlightText} onChange={handleChange} />
                        </Label>
                        <Label style={{ marginRight: "10px" }}>
                            Button Link:
                            <Input type="text" name="lastHeroSection.buttonLink" value={formData.lastHeroSection.buttonLink} onChange={handleChange} />
                        </Label>
                    </div>
                    <Label>
                        Media File:
                    </Label>
                    {renderFileInput('lastHeroSection.mediaFile')}
                </FormSection>
                <div style={{ paddingBottom: "50px", display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
                    <SubmitButton type="submit">Update</SubmitButton>
                </div>
            </form>
        </FormContainer>
    );
};

export default HomeSettingsForm;
