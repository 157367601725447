import Gallery from "../components/Galary";
import Navbar from "../components/Navbar";
import ReviewSection from "../components/ReviewSection";
import Footer from "../components/Footer";
import { useEffect, useState } from "react";
import { _api } from "../utilities/api"


export const getMediaFileUrl = linkk => process.env.REACT_APP_SERVER_URL + "/uploads/" + linkk

export default function Home() {
    const [homeSettings, setHomeSettings] = useState({})

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        let result = await _api({
            method: "GET",
            url: `/home_settings`
        })

        console.log(result?.data);
        setHomeSettings(result?.data)
    }

    const images = [
        'https://via.placeholder.com/200x300',
        'https://via.placeholder.com/200x300',
        'https://via.placeholder.com/200x300',
        'https://via.placeholder.com/200x300',
        'https://via.placeholder.com/200x300',
        'https://via.placeholder.com/200x300',
        'https://via.placeholder.com/200x300',
        'https://via.placeholder.com/200x300',
        'https://via.placeholder.com/200x300',
        'https://via.placeholder.com/200x300',
    ];


    const roundedArr = [
        "rounded-tl-[20px] rounded-tr-[130px] rounded-br-[20px] rounded-bl-[20px]",
        "rounded-tl-[20px] rounded-tr-[144px] rounded-br-[10px] rounded-bl-[20px]",
        "rounded-tl-[33px] rounded-tr-[33px] rounded-br-[33px] rounded-bl-[33px]",
        "rounded-tl-[0px] rounded-tr-[103px] rounded-br-[0px] rounded-bl-[43px]"
    ]


    return (
        <>
            <div className="flex flex-col-reverse sm:flex-row">
                <div className="left bg-white text-black w-full sm:w-1/2 flex flex-col justify-center p-8 text-right">
                    <h1 className="text-5xl sm:text-7xl font-bold mb-4 capitalize">{homeSettings?.heroArea?.heading}</h1>
                    <p className="text-lg">{homeSettings?.heroArea?.subheading}</p>
                    <div className="flex-row">
                        <button className="primary-btn mt-2">
                            <a target="blank" href={homeSettings?.heroArea?.buttonLink}>Order Now</a>
                        </button>
                    </div>
                </div>
                <div className="relative w-full sm:w-1/2">
                    <div style={{ zIndex: 999 }} className="absolute inset-0 gradient-overlay"></div>
                    <video src={getMediaFileUrl(homeSettings?.heroArea?.mediaFile)} autoPlay muted loop className="relative z-10 w-full h-full object-cover"></video>
                </div>
            </div>

            {/* Feature section */}
            <section className="min-h-[70vh]">
                <h1 className='my-5 text-2xl font-bold text-center border-b-2 pb-2 sm:max-w-[400px] w-[80%] mx-auto border-[#2D408F] text-[#2D408F]'>Specifications</h1>
                <div className="flex flex-col sm:flex-row items-center justify-center">
                    {
                        homeSettings?.features && homeSettings?.features?.map((feature, i) => {
                            return (<>
                                <div className={`group w-[95%] sm:w-1/5 relative h-[320px] m-[5px] ${roundedArr[i]} overflow-hidden border-2 border-black`}>
                                    <div className="group-hover:scale-110 ition duration-200  img-box w-full h-[220px]">
                                        <img src={getMediaFileUrl(feature?.mediaFile)} alt="" className="w-full h-full" />
                                    </div>
                                    <div className="w-full h-[100px] p-2 flex items-center px-4 text-black font-semibold">
                                        {feature?.subheading}
                                        {/* <h2 className={`text-[30px] font-semibold`}>
                                        {feature?.heading}
                                    </h2> */}
                                    </div>
                                </div>
                            </>)
                        })
                    }
                </div>
            </section>

            {/* Galary Section */}
            <Gallery images={homeSettings?.Gallery} />

            {/* Review Section */}
            {/* <ReviewSection /> */}

            {/* OLD Section */}
            {/* <section className="container">
                <div className="row">
                    <div className="col-md-6 flex items-end justify-center flex-col">
                        <div className="flex items-end justify-center flex-col text-right">
                            <h2 className="text-xl my-1">
                                {homeSettings?.lastHeroSection?.heading}
                            </h2>
                            <h2 className="text-2xl font-bold my-1">
                                {homeSettings?.lastHeroSection?.highlightText}
                            </h2>
                            <div className="text-xl bg-black text-white rounded px-4 py-2 my-2 cursor-pointer hover:bg-gray-700">
                                <a target="blank" href={homeSettings?.lastHeroSection?.buttonLink}>Order Now</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="img-box">
                            <img src={getMediaFileUrl(homeSettings?.lastHeroSection?.mediaFile)} alt="" />
                        </div>
                    </div>
                </div>
            </section> */}

            {/* New Section */}
            <section className="container max-w-[600px] min-h-[70vh] flex justify-center items-center">
                <div className="flex flex-col items-center justify-center text-center">
                    <h2 className="text-2xl font-bold my-1">
                        {homeSettings?.lastHeroSection?.heading}
                    </h2>
                    <h2 className="text-xl my-1 font-semibold">
                        {homeSettings?.lastHeroSection?.highlightText}
                    </h2>
                    <div className="primary-btn my-4">
                        <a target="blank" href={homeSettings?.lastHeroSection?.buttonLink}>Order Now</a>
                    </div>
                </div>
            </section>

            {/* end discount section */}

            {/* brand section */}
            {/* <section className="container mx-auto">
                <div className="heading_container">
                    <h2 className="pr-4 mb-3">
                        Other Products from <b><i>'Prismonic'</i></b>
                    </h2>
                </div>
                <a href className="bg-black text-white px-4 py-2 rounded">
                    See More
                </a>
            </section> */}
            {/* end brand section */}
        </>

    );
}
