import SearchIcon from '@mui/icons-material/Search';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import PersonIcon from '@mui/icons-material/Person';
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { _api } from '../utilities/api';
import { getMediaFileUrl } from '../pages/Home';
import MenuIcon from '@mui/icons-material/Menu';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const Navbar = () => {
  const [user, setUser] = useState(null)
  const [homeSettings, setHomeSettings] = useState({})
  const [pathname, setPathname] = useState("")
  const location = useLocation();
  const [open, setOpen] = useState(false)

  useEffect(() => {
    let u = window.localStorage.getItem("gms_user")
    setUser(JSON.parse(u))
    getData()
  }, [])


  useEffect(() => {
    setOpen(false)
    setPathname(location.pathname);
    console.log(location, "---location");
  }, [location]);

  const getData = async () => {
    let result = await _api({
      method: "GET",
      url: `/home_settings`
    })

    console.log(result?.data);
    setHomeSettings(result?.data)
  }


  const linkItem = ({ to, title, condition }) => {
    if (condition === false) return;
    const activeClass = to === pathname ? 'bg-[#2D408F] text-white shadow-xl' : '';

    return <Link to={to} className={`rounded-full transition duration-150 site-navbar-link capitalize text-sm px-2 py-1 ${activeClass}`}>
      {title}
    </Link>
  }

  const loginBtns = () => {
    return window.localStorage.getItem("gms_access_token") ?
      <Link to={"/profile"} className="mx-2 bg-[#2D408F] w-[30px] h-[30px] grid place-items-center rounded-full" title='profile'>
        <img src='https://cdn-icons-png.flaticon.com/512/149/149071.png' alt="Profile" className='w-full h-full object-cover' />
      </Link> :
      <Link to="/login" className="bg-[#2D408F] text-white px-4 py-2 rounded site-navbar-link uppercase text-sm">
        Login
      </Link>
  }


  return (<>
    <header className="site-header absolute w-full top-0 bg-[white]" style={{ zIndex: 1000 }}>
      <div className="container flex flex-col lg:flex-row justify-between items-center py-2">
        <div className="flex flex-row items-center justify-between w-full">
          <Link to="/" style={{ textDecoration: "none" }}>
            <div className="brand header-brand flex items-center">
              <h1 className="mr-2">
                <img className="w-[160px]" src={getMediaFileUrl(homeSettings?.logo)} alt="Logo" />
              </h1>
            </div>
          </Link>
          <div className="flex flex-row items-end justify-end">
            <div className="flex lg:hidden" onClick={() => setOpen(!open)}>{open ? <HighlightOffIcon /> : <MenuIcon />}</div>
            <div className="flex lg:hidden">{loginBtns()}</div>
          </div>
        </div>
        <div className={`flex items-center lg:absolute lg:left-[50%] lg:translate-x-[-50%] lg:top-[50%] lg:translate-y-[-50%] transform bg-[#E5D6D6] bg-opacity-90 p-2 lg:rounded-full
        absolute -top-[1000%] transition-all duration-1000 flex-col lg:flex-row w-full lg:w-auto rounded-none ${open ? 'menuList-active' : ''}`}>
          {
            [
              { title: "Home", to: "/" },
              { title: "About", to: "/about" },
              { title: "Contact", to: "/contact" },
              { title: "Dashboard", to: "/dashboard", condition: user?.role == 0 },
              { title: "Orders", to: "/orders", condition: Boolean(window.localStorage.getItem("gms_access_token")) },
              { title: "Home Settings", to: "/home-settings", condition: user?.role == 0 },
            ].map(linkItem)

          }
        </div>
        <div className="flex flex-row w-1/3 items-center justify-end">
          <Link to="/login" className="hidden lg:flex bg-[#2D8F4E] text-white px-4 py-1 rounded-full mx-2">
            Chat on Whatsapp
          </Link>
          <div className="hidden lg:flex items-center justify-center">{loginBtns()}</div>
        </div>
      </div >
    </header >
  </>)
}

export default Navbar;