import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    TextField,
    MenuItem,
    Select,
    InputLabel,
    OutlinedInput,
    Switch,
    CircularProgress,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { _api } from '../../utilities/api'; // Adjust import according to your structure
import { useNavigate, useSearchParams } from 'react-router-dom';

const initialData = {
    products: ["BOOKS PRINTING & BINDING", "BULK PHOTOSTATE", "BULK PRINTOUTS"],
    size: [{
        title: "A3",
    },
    {
        title: "A4",
    },
    {
        title: "A5",
    }],
    paper: [{
        title: "65 GSM",
    }, {
        title: "70 GSM",
    }, {
        title: "75 GSM",
    }],
    printColor: [{
        title: "COLOR",
        // colorCostPerPage: 20,
    }, {
        title: "BLACK & WHITE",
        // colorCostPerPage: 15,
    }],
    printSides: ["SINGLE SIDE", "BOTH SIDES"],
    binding: [{
        title: "PERFECT BINDING",
        bindingCharges: 100,
        singleBindingWeight: 10, // In Grams
    }, {
        title: "SPIRAL BINDING",
        bindingCharges: 150,
        singleBindingWeight: 10, // In Grams
    }, {
        title: "WIRO BINDING",
        bindingCharges: 200,
        singleBindingWeight: 10, // In Grams
    }, {
        title: "Hard BINDING",
        bindingCharges: 250,
        singleBindingWeight: 10, // In Grams
    }, {
        title: "ONLY STAPLER",
        bindingCharges: 300,
        singleBindingWeight: 10, // In Grams
    }, {
        title: "STAPLER & TAPE",
        bindingCharges: 350,
        singleBindingWeight: 10, // In Grams
    }],
    shipping: ["COURIER", "TRANSPORT", "BY HAND", "SELF COLLECTION"],
    // shippingCharges: [{
    //     weight: 5, // 5KG if weight is under 5kg like 3,4,4.5 etc. then courier charges will be 200.
    //     price: 200,
    // }, {
    //     weight: 10, // 5KG
    //     price: 400,
    // }],
    shippingChargesPerKg: 100,
    weightOfSinglePage: {
        "A3_65 GSM": 10,
        "A3_70 GSM": 15,
        "A3_75 GSM": 20,
        "A4_65 GSM": 12,
        "A4_70 GSM": 18,
        "A4_75 GSM": 24,
        "A5_65 GSM": 15,
        "A5_70 GSM": 20,
        "A5_75 GSM": 25,
    },
    priceOfSinglePage: {
        "A3_65 GSM": [10, 20, 0, 0],
        "A3_70 GSM": [15, 25, 0, 0],
        "A3_75 GSM": [20, 20, 0, 0],
        "A4_65 GSM": [12, 22, 0, 0],
        "A4_70 GSM": [18, 28, 0, 0],
        "A4_75 GSM": [24, 24, 0, 0],
        "A5_65 GSM": [15, 25, 0, 0],
        "A5_70 GSM": [20, 20, 0, 0],
        "A5_75 GSM": [25, 25, 0, 0],
    },
    gstPrice: 0.18, // Percentage
}


let stateList = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttarakhand",
    "Uttar Pradesh",
    "West Bengal",
    "Andaman and Nicobar",
    "Chandigarh",
    "Dadra and Nagar",
    "Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry"
]

export default function SettingsForm() {
    const [tab, setTab] = useState("");
    const [user, setUser] = useState("")

    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    let _tab = searchParams.get("tab")

    useEffect(() => {
        setTab(_tab || "my_profile")
    }, [_tab])


    useEffect(() => {
        let u = window.localStorage.getItem("gms_user")
        setUser(JSON.parse(u))
    }, [])

    const renderTab = () => {
        switch (tab) {
            case "my_profile": return <MyProfile />
            case "manage_password": return <ManagePassword />
            case "manage_settings": return <ManageSettings />
        }
    }

    return (
        <div className='min-h-[70vh] h-[80vh] flex pb-4'>
            <div className="w-[320px] h-full bg-gray-100 p-4 mr-2 rounded-lg gap-2 flex flex-col">
                <h1 className='font-bold p-2'>Settings</h1>
                <hr />
                <div onClick={() => {
                    navigate(`/profile?tab=my_profile`)
                }} className={`${tab == "my_profile" ? "p-2 rounded cursor-pointer bg-indigo-600 border border-indigo-600 text-white" : "p-2 rounded cursor-pointer hover:bg-gray-300"}`}>
                    My Profile
                </div>
                <div onClick={() => {
                    navigate(`/profile?tab=manage_password`)
                }} className={`${tab == "manage_password" ? "p-2 rounded cursor-pointer bg-indigo-600 border border-indigo-600 text-white" : "p-2 rounded cursor-pointer hover:bg-gray-300"}`}>
                    Manage Password
                </div>
                {
                    user?.role == 0 && <div onClick={() => {
                        navigate(`/profile?tab=manage_settings`)
                    }} className={`${tab == "manage_settings" ? "p-2 rounded cursor-pointer bg-indigo-600 border border-indigo-600 text-white" : "p-2 rounded cursor-pointer hover:bg-gray-300"}`}>
                        Manage Settings
                    </div>}
                <div onClick={() => {
                    if (window.confirm("Are you sure to Logout?")) {
                        window.localStorage.removeItem("gms_user")
                        window.localStorage.removeItem("gms_access_token")
                        window.location.href = "/"
                    }
                }} className="p-2 rounded cursor-pointer hover:bg-gray-300 text-red-500">
                    Logout
                </div>
            </div>
            <div className="flex flex-1 h-full">
                {renderTab()}
            </div>
            {/* <Box sx={{
                width: "50%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: "20px",
                justifyContent: "space-between",
            }}>
                <Typography color='black'>Welcome, {user?.email}</Typography>
                <div className="flex items-center gap-2">
                    <Button onClick={() => {
                        if (window.confirm("Are you sure?")) {
                            window.localStorage.removeItem("gms_user")
                            window.localStorage.removeItem("gms_access_token")
                            window.location.href = "/"
                        }
                    }}>Logout</Button>
                    {
                        user?.role == 0 &&
                        <>
                            <label className='font-bold mt-[5px]' htmlFor="">Special Price</label>
                            <Switch checked={specialStatus} onChange={(e) => {
                                setSpecialStatus(e.target.checked)
                            }} />
                        </>
                    }
                </div>
            </Box>
            {user?.role == 0 && <ManageSettings />} */}
        </div>
    );
}

const MyProfile = () => {
    const [user, setUser] = useState("")

    useEffect(() => {
        let u = window.localStorage.getItem("gms_user")
        setUser(JSON.parse(u))
    }, [])

    return <div className='p-6 w-full flex flex-col gap-4'>
        <p>Hello, {user?.email}</p>
        <hr />
        <span>Coming soon...</span>
    </div>
}


const ManagePassword = ({ }) => {
    const [user, setUser] = useState("")

    useEffect(() => {
        let u = window.localStorage.getItem("gms_user")
        setUser(JSON.parse(u))
    }, [])

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Add logic to handle form submission
        if (newPassword !== confirmPassword) {
            alert('New password and confirm password do not match.');
            return;
        }

        let data;
        if (user?.isPassword) {
            console.log('Changing password', { oldPassword, newPassword });
            // Call API to change the password
            // Call API to create a new password
            let results = await _api({
                method: "POST",
                url: `/users/${user?._id}/update`,
                data: {
                    update_type: "change_password",
                    oldPassword,
                    newPassword
                },
            })

            if (results?.data?.success) {
                alert("Password Changed")
                data = results?.data?.data;
            } else {
                alert(results?.data?.message)
            }

        } else {
            console.log('Creating new password', { newPassword });
            // Call API to create a new password
            let results = await _api({
                method: "POST",
                url: `/users/${user?._id}/update`,
                data: {
                    password: newPassword
                },
            })

            if (results?.data?.success) {
                data = results?.data?.data;
                alert("Password Changed")
            } else {
                alert(results?.data?.message)
            }
        }

        if (data) window.localStorage.setItem("gms_user", JSON.stringify(data))
        if (data) window.location.reload();

    };

    return (
        <div className='p-6 flex flex-col gap-4 w-full'>
            <h2 className='font-semibold'>{user?.isPassword ? 'Change Password' : 'Create New Password'}</h2>
            <hr />
            <form onSubmit={handleSubmit} className='space-y-4 max-w-[400px]'>
                {user?.isPassword && (
                    <div>
                        <label htmlFor="old-password">Old Password</label>
                        <input
                            type="password"
                            id="old-password"
                            className='border rounded p-2 w-full'
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            required
                        />
                    </div>
                )}
                <div>
                    <label htmlFor="new-password">New Password</label>
                    <input
                        type="password"
                        id="new-password"
                        className='border rounded p-2 w-full'
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="confirm-password">Confirm Password</label>
                    <input
                        type="password"
                        id="confirm-password"
                        className='border rounded p-2 w-full'
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className='bg-blue-500 text-white py-2 px-4 rounded'>
                    {user?.isPassword ? 'Change Password' : 'Create Password'}
                </button>
            </form>
        </div>
    );
};


const ManageSettings = () => {
    const [fd, setFD] = useState({})
    const [formJSON, setFormJSON] = useState(null)
    const [loading, setLoading] = useState(true)
    const [loading2, setLoading2] = useState(false)
    const [user, setUser] = useState("")
    const [specialStatus, setSpecialStatus] = useState(false)

    useEffect(() => {
        let u = window.localStorage.getItem("gms_user")
        setUser(JSON.parse(u))
    }, [])

    useEffect(() => {
        _api({
            method: "GET",
            url: "/settings",
        }).then(response => {
            console.log(response, "--response")
            let data = initialData;
            if (response?.data?.data?.settings && response?.data?.data?.settings?.trim() != "" && !specialStatus) {
                data = JSON.parse(response?.data?.data?.settings)
            }
            if (response?.data?.data?.specialSettings && response?.data?.data?.specialSettings?.trim() != "" && specialStatus) {
                data = JSON.parse(response?.data?.data?.specialSettings)
            }

            setFormJSON({
                ...initialData,
                ...data,
            })

            console.log({
                ...initialData,
                ...data,
            });


            setLoading(false)
        })
    }, [specialStatus])


    useEffect(() => {
        let oldd = { ...formJSON }
        let w = {}
        let p = {}
        oldd?.size?.forEach(sObj => {
            oldd?.paper?.forEach(pOjb => {
                w[`${sObj?.title}_${pOjb.title}`] = oldd?.weightOfSinglePage[`${sObj?.title}_${pOjb.title}`] || 0;
                p[`${sObj?.title}_${pOjb.title}`] = oldd?.priceOfSinglePage[`${sObj?.title}_${pOjb.title}`] || 0;
            })
        })
        setFormJSON({
            ...formJSON,
            weightOfSinglePage: w,
            priceOfSinglePage: p,
        });
    }, [JSON.stringify(formJSON?.size), JSON.stringify(formJSON?.paper)])

    const handleChange = (e) => {
        let { name, value } = e.target
        setFD({ ...fd, [name]: value })
    }


    const handleSubmit = async () => {
        setLoading2(true);
        console.log(JSON.stringify(formJSON))
        let results = await _api({
            method: "PATCH",
            url: "/settings",
            data: !specialStatus ? {
                settings: JSON.stringify(formJSON)
            } : {
                specialSettings: JSON.stringify(formJSON)
            },
        })


        if (results?.data?.success) {
            // alert(results.data.message)
        }

        setLoading2(false);
    }

    if (!formJSON) {
        return <CircularProgress />
    }

    return <div className="p-6 w-full h-full overflow-auto">
        <div className="">
            <div className="flex items-center justify-between gap-2 p-2 rounded mb-4 bg-gray-200 sticky top-0 shadow-xl">
                <div className="flex items-center gap-2">
                    <label className='font-bold mt-[5px]' htmlFor="">Special Price</label>
                    <Switch checked={specialStatus} onChange={(e) => {
                        setSpecialStatus(e.target.checked)
                    }} />
                </div>

                <Button disabled={loading2} className='' variant='contained' onClick={handleSubmit}>
                    {loading2 ? "Saving..." : "Save"}
                </Button>
            </div>

            <div className="max-w-2xl mx-auto">

                <section className='mb-4  pb-4'>
                    <label htmlFor="" className='flex flex-row justify-between items-center border-b-2 border-gray-600'>
                        <span>Products</span>
                        {/* <button className='flex items-center justify-center text-lg pb-1 focus:outline-none rounded-full text-green-500 hover:bg-green-500 hover:text-white w-[20px] h-[20px]'>+</button> */}
                    </label>
                    <ul>
                        {formJSON?.products?.map((item, i) => (
                            <li key={i} className='bg-gray-300 px-2 py-1 my-1 rounded flex flex-row justify-between'>
                                <span>{item}</span>
                                <button
                                    onClick={() => {
                                        let oldd = formJSON.products
                                        oldd.splice(i, 1)
                                        setFormJSON({
                                            ...formJSON,
                                            products: oldd
                                        })
                                    }} className='flex items-center justify-center text-xs  rounded-full text-red-500 hover:bg-red-500 hover:text-white w-[20px] h-[20px]'>X</button>
                            </li>
                        ))}
                    </ul>
                    <div className="flex flex-row justify-between items-center">
                        <input
                            type="text"
                            name="productTitle"
                            placeholder='Enter Product Title'
                            value={fd.productTitle}
                            onChange={handleChange}
                            className="p-2 w-full rounded-l h-[40px] border-2 border-green-500 focus:outline-none"
                            required
                        />
                        <button
                            onClick={() => {
                                let oldd = formJSON.products
                                oldd.push(fd?.productTitle)

                                setFormJSON({
                                    ...formJSON,
                                    products: oldd
                                })


                                setFD({
                                    ...fd,
                                    productTitle: ""
                                })
                            }} className='flex items-center justify-center text-lg pb-1 m-0 focus:outline-none rounded-r bg-green-500 text-white w-[40px] h-[40px]'>+</button>
                    </div>
                </section>
                <section className='mb-4  pb-4'>
                    <label htmlFor="" className='flex flex-row justify-between items-center border-b-2 border-gray-600'>
                        <span>Size</span>
                        {/* <button className='flex items-center justify-center text-lg pb-1 focus:outline-none rounded-full text-green-500 hover:bg-green-500 hover:text-white w-[20px] h-[20px]'>+</button> */}
                    </label>
                    <ul>
                        {formJSON?.size?.map((item, i) => (
                            <li key={i} className='bg-gray-300 px-2 py-1 my-1 rounded flex flex-row justify-between'>
                                <span>{item?.title}</span>
                                <button
                                    onClick={() => {
                                        let oldd = formJSON.size
                                        oldd.splice(i, 1)
                                        setFormJSON({
                                            ...formJSON,
                                            size: oldd
                                        })
                                    }} className='flex items-center justify-center text-xs  rounded-full text-red-500 hover:bg-red-500 hover:text-white w-[20px] h-[20px]'>X</button>
                            </li>
                        ))}
                    </ul>
                    <div className="flex flex-row justify-between items-center">
                        <input
                            type="text"
                            name="size"
                            placeholder='Enter Size'
                            value={fd.size}
                            onChange={handleChange}
                            className="p-2 w-full rounded-l h-[40px] border-2 border-green-500 focus:outline-none"
                            required
                        />
                        <button
                            onClick={() => {
                                let oldd = formJSON.size
                                oldd.push({ title: fd?.size })

                                setFormJSON({
                                    ...formJSON,
                                    size: oldd
                                })


                                setFD({
                                    ...fd,
                                    size: ''
                                })
                            }}
                            className='flex items-center justify-center text-lg pb-1 m-0 focus:outline-none rounded-r bg-green-500 text-white w-[40px] h-[40px]'>+</button>
                    </div>
                </section>
                <section className='mb-4  pb-4'>
                    <label htmlFor="" className='flex flex-row justify-between items-center border-b-2 border-gray-600'>
                        <span>Paper</span>
                        {/* <button className='flex items-center justify-center text-lg pb-1 focus:outline-none rounded-full text-green-500 hover:bg-green-500 hover:text-white w-[20px] h-[20px]'>+</button> */}
                    </label>
                    <ul>
                        {formJSON?.paper?.map((item, i) => (
                            <li key={i} className='bg-gray-300 px-2 py-1 my-1 rounded flex flex-row justify-between'>
                                <span>{item?.title}</span>
                                <button
                                    onClick={() => {
                                        let oldd = formJSON.paper
                                        oldd.splice(i, 1)
                                        setFormJSON({
                                            ...formJSON,
                                            paper: oldd
                                        })
                                    }}
                                    className='flex items-center justify-center text-xs  rounded-full text-red-500 hover:bg-red-500 hover:text-white w-[20px] h-[20px]'>X</button>
                            </li>
                        ))}
                    </ul>
                    <div className="flex flex-row justify-between items-center">
                        <input
                            type="text"
                            name="paper"
                            placeholder='Enter Paper Type'
                            value={fd.paper}
                            onChange={handleChange}
                            className="p-2 w-full rounded-l h-[40px] border-2 border-green-500 focus:outline-none"
                            required
                        />
                        <button
                            onClick={() => {
                                let oldd = formJSON.paper
                                oldd.push({ title: fd?.paper })

                                setFormJSON({
                                    ...formJSON,
                                    paper: oldd
                                })

                                setFD({
                                    ...fd,
                                    paper: ''
                                })
                            }}
                            className='flex items-center justify-center text-lg pb-1 m-0 focus:outline-none rounded-r bg-green-500 text-white w-[40px] h-[40px]'>+</button>
                    </div>
                </section>

                <section className='mb-4  pb-4'>
                    <label htmlFor="" className='flex flex-row justify-between items-center border-b-2 border-gray-600'>
                        <span>Print Colors</span>
                        {/* <button className='flex items-center justify-center text-lg pb-1 focus:outline-none rounded-full text-green-500 hover:bg-green-500 hover:text-white w-[20px] h-[20px]'>+</button> */}
                    </label>
                    <ul>
                        {formJSON?.printColor?.map((item, i) => (
                            <li key={i} className='bg-gray-300 px-2 py-1 my-1 rounded flex flex-row justify-between'>
                                <span>{item?.title}</span>
                            </li>
                        ))}
                    </ul>
                </section>


                <section className='mb-4  pb-4'>
                    <label htmlFor="" className='flex flex-row justify-between items-center border-b-2 border-gray-600'>
                        <span>Print Sides</span>
                    </label>
                    <ul>
                        {formJSON?.printSides?.map((item, i) => (
                            <li key={i} className='bg-gray-300 px-2 py-1 my-1 rounded flex flex-row justify-between'>
                                <span>{item}</span>
                            </li>
                        ))}
                    </ul>
                </section>

                <section className='mb-4  pb-4'>
                    <label htmlFor="" className='flex flex-row justify-between items-center border-b-2 border-gray-600'>
                        <span>Binding</span>
                        {/* <button className='flex items-center justify-center text-lg pb-1 focus:outline-none rounded-full text-green-500 hover:bg-green-500 hover:text-white w-[20px] h-[20px]'>+</button> */}
                    </label>
                    <ul>
                        {formJSON?.binding?.map((item, i) => (
                            <li key={i} className='bg-gray-300 px-2 py-1 my-1 rounded flex flex-row justify-between'>
                                <span>{item?.title} - <small>{item?.bindingCharges}/-</small> - <small>{item?.singleBindingWeight}g</small></span>
                                <button onClick={() => {
                                    let oldd = formJSON.binding
                                    oldd.splice(i, 1)
                                    setFormJSON({
                                        ...formJSON,
                                        binding: oldd
                                    })
                                }} className='flex items-center justify-center text-xs  rounded-full text-red-500 hover:bg-red-500 hover:text-white w-[20px] h-[20px]'>X</button>
                            </li>
                        ))}
                    </ul>
                    <div className="flex flex-row justify-between items-center mb-2">
                        <input
                            type="text"
                            name="bindingType"
                            placeholder='Enter Binding Type'
                            value={fd.bindingType}
                            onChange={handleChange}
                            className="p-2 mr-2 w-2/4 rounded-l h-[40px] border-2 border-green-500 focus:outline-none"
                            required
                        />
                        <input
                            type="text"
                            name="bindingPrice"
                            placeholder='Price'
                            value={fd.bindingPrice}
                            onChange={handleChange}
                            className="p-2 mr-2 w-1/4 rounded-l h-[40px] border-2 border-green-500 focus:outline-none"
                            required
                        />
                        <input
                            type="text"
                            name="bindingWeight"
                            placeholder='Weight (Grams)'
                            value={fd.bindingWeight}
                            onChange={handleChange}
                            className="p-2 mr-2 w-1/4 rounded-l h-[40px] border-2 border-green-500 focus:outline-none"
                            required
                        />
                        <button
                            onClick={() => {
                                let oldd = formJSON.binding
                                oldd.push({ title: fd?.bindingType, bindingCharges: fd?.bindingPrice, singleBindingWeight: fd?.bindingWeight })

                                setFormJSON({
                                    ...formJSON,
                                    binding: oldd
                                })


                                setFD({
                                    ...fd,
                                    bindingType: '',
                                    bindingPrice: '',
                                    bindingWeight: ''
                                })

                            }} className='flex items-center justify-center text-lg pb-1 m-0 focus:outline-none rounded float-right bg-green-500 text-white min-w-[40px] h-[40px]'>+</button>
                    </div>
                </section>

                <section className='mb-4  pb-4'>
                    <label htmlFor="" className='flex flex-row justify-between items-center border-b-2 border-gray-600'>
                        <span>Shipping</span>
                        {/* <button className='flex items-center justify-center text-lg pb-1 focus:outline-none rounded-full text-green-500 hover:bg-green-500 hover:text-white w-[20px] h-[20px]'>+</button> */}
                    </label>
                    <ul>
                        {formJSON?.shipping?.map((item, i) => (
                            <li key={i} className='bg-gray-300 px-2 py-1 my-1 rounded flex flex-row justify-between'>
                                <span>{item}</span>
                                <button onClick={() => {
                                    let oldd = formJSON.shipping
                                    oldd.splice(i, 1)
                                    setFormJSON({
                                        ...formJSON,
                                        shipping: oldd
                                    })
                                }} className='flex items-center justify-center text-xs  rounded-full text-red-500 hover:bg-red-500 hover:text-white w-[20px] h-[20px]'>X</button>
                            </li>
                        ))}
                    </ul>
                    <div className="flex flex-row justify-between items-center">
                        <input
                            type="text"
                            name="shippingType"
                            placeholder='Enter Shipping Type'
                            value={fd.shippingType}
                            onChange={handleChange}
                            className="p-2 w-full rounded-l h-[40px] border-2 border-green-500 focus:outline-none"
                            required
                        />
                        <button
                            onClick={() => {
                                let oldd = formJSON.shipping
                                oldd.push(fd?.shippingType)

                                setFormJSON({
                                    ...formJSON,
                                    shipping: oldd
                                })

                                setFD({
                                    ...fd,
                                    shippingType: ''
                                })
                            }} className='flex items-center justify-center text-lg pb-1 m-0 focus:outline-none rounded-r bg-green-500 text-white w-[40px] h-[40px]'>+</button>
                    </div>
                </section>
                <section className='mb-4  pb-4'>
                    <label htmlFor="" className='flex flex-row justify-between items-center border-b-2 border-gray-600'>
                        <span>Shipping Charges Per Kg <b>(All States)</b></span>
                    </label>
                    <ul className='flex flex-row flex-wrap justify-between'>
                        {
                            stateList?.map((state, index) => {
                                return <div className='w-[30%] flex flex-col gap-1 mb-3'>
                                    <label className='p-0 m-0 text-sm' htmlFor="">{state}</label>
                                    <input
                                        type="number"
                                        value={formJSON.shippingChargesPerKg?.[state] || 0}
                                        onChange={(event) => {
                                            let dd = formJSON?.shippingChargesPerKg;
                                            dd[state] = event?.target?.value
                                            console.log({ dd });

                                            setFormJSON({
                                                ...formJSON,
                                                shippingChargesPerKg: dd
                                            })

                                            setFD({
                                                ...fd,
                                                shippingChargesPerKg: dd
                                            })
                                        }}
                                        placeholder='Enter Weight'
                                        className="p-2 mr-2 w-full rounded-l h-[40px] border-2 border-green-400 focus:outline-none"
                                        required
                                    />
                                </div>
                            })
                        }

                    </ul>
                </section>

                <section className='mb-4  pb-4'>
                    <label htmlFor="" className='flex flex-row justify-between items-center border-b-2 border-gray-600'>
                        <span>Weight of Single Page (Grams)</span>
                        {/* <button className='flex items-center justify-center text-lg pb-1 focus:outline-none rounded-full text-green-500 hover:bg-green-500 hover:text-white w-[20px] h-[20px]'>+</button> */}
                    </label>
                    <ul>
                        {Object.keys(formJSON?.weightOfSinglePage)?.map((key, i) =>
                            <li key={i} className='bg-gray-300 px-2 py-1 my-1 rounded flex flex-row justify-between items-center'>
                                <span>{key}</span>
                                <input
                                    type="text"
                                    value={formJSON.weightOfSinglePage[key]}
                                    onChange={(event) => {
                                        setFormJSON({
                                            ...formJSON,
                                            weightOfSinglePage: {
                                                ...formJSON.weightOfSinglePage,
                                                [key]: event.target.value
                                            }
                                        })
                                    }}
                                    placeholder='Enter Weight'
                                    className="p-2 mr-2 w-1/3 rounded-l h-[30px] border-2 border-green-500 focus:outline-none"
                                    required
                                />
                            </li>
                        )}
                    </ul>
                </section>


                <section className='mb-4  pb-4'>
                    <label htmlFor="" className='flex flex-row justify-between items-center border-b-2 border-gray-600'>
                        <span>Page Prices (Rupees)</span>
                        {/* <button className='flex items-center justify-center text-lg pb-1 focus:outline-none rounded-full text-green-500 hover:bg-green-500 hover:text-white w-[20px] h-[20px]'>+</button> */}
                    </label>
                    <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-sm">
                        <thead className="bg-gray-100 border-b border-gray-200">
                            <tr>
                                <th className="px-4 py-2 text-left text-gray-700 font-semibold">Page</th>
                                <th className="px-4 py-2 text-left text-gray-700 font-semibold">Single Color</th>
                                <th className="px-4 py-2 text-left text-gray-700 font-semibold">Single B&W</th>
                                <th className="px-4 py-2 text-left text-gray-700 font-semibold">Both Color</th>
                                <th className="px-4 py-2 text-left text-gray-700 font-semibold">Both B&W</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(formJSON?.priceOfSinglePage)?.map((key, i) => (
                                <tr key={i} className="bg-gray-50 even:bg-gray-100 border-b border-gray-200">
                                    <td className="px-4 py-2 text-gray-800">
                                        <span>{key}</span>
                                    </td>
                                    <td className="px-4 py-2">
                                        <input
                                            type="number"
                                            value={formJSON.priceOfSinglePage[key][0]}
                                            placeholder="Enter Price"
                                            onChange={(event) => {
                                                setFormJSON({
                                                    ...formJSON,
                                                    priceOfSinglePage: {
                                                        ...formJSON.priceOfSinglePage,
                                                        [key]: [event.target.value, formJSON.priceOfSinglePage[key][1], formJSON.priceOfSinglePage[key][2], formJSON.priceOfSinglePage[key][3]]
                                                    }
                                                });
                                            }}
                                            className="p-2 w-20 text-center bg-white border border-gray-300 rounded-md shadow-sm focus:border-green-500 focus:ring-green-500 focus:outline-none"
                                            required
                                        />
                                    </td>
                                    <td className="px-4 py-2">
                                        <input
                                            type="number"
                                            value={formJSON.priceOfSinglePage[key][1]}
                                            placeholder="Enter Price"
                                            onChange={(event) => {
                                                setFormJSON({
                                                    ...formJSON,
                                                    priceOfSinglePage: {
                                                        ...formJSON.priceOfSinglePage,
                                                        [key]: [formJSON.priceOfSinglePage[key][0], event.target.value, formJSON.priceOfSinglePage[key][2], formJSON.priceOfSinglePage[key][3]]
                                                    }
                                                });
                                            }}
                                            className="p-2 w-20 text-center bg-white border border-gray-300 rounded-md shadow-sm focus:border-green-500 focus:ring-green-500 focus:outline-none"
                                            required
                                        />
                                    </td>
                                    <td className="px-4 py-2">
                                        <input
                                            type="number"
                                            value={formJSON.priceOfSinglePage[key][2]}
                                            placeholder="Enter Price"
                                            onChange={(event) => {
                                                setFormJSON({
                                                    ...formJSON,
                                                    priceOfSinglePage: {
                                                        ...formJSON.priceOfSinglePage,
                                                        [key]: [formJSON.priceOfSinglePage[key][0], formJSON.priceOfSinglePage[key][1], event.target.value, formJSON.priceOfSinglePage[key][3]]
                                                    }
                                                });
                                            }}
                                            className="p-2 w-20 text-center bg-white border border-gray-300 rounded-md shadow-sm focus:border-green-500 focus:ring-green-500 focus:outline-none"
                                            required
                                        />
                                    </td>
                                    <td className="px-4 py-2">
                                        <input
                                            type="number"
                                            value={formJSON.priceOfSinglePage[key][3]}
                                            placeholder="Enter Price"
                                            onChange={(event) => {
                                                setFormJSON({
                                                    ...formJSON,
                                                    priceOfSinglePage: {
                                                        ...formJSON.priceOfSinglePage,
                                                        [key]: [formJSON.priceOfSinglePage[key][0], formJSON.priceOfSinglePage[key][1], formJSON.priceOfSinglePage[key][2], event.target.value]
                                                    }
                                                });
                                            }}
                                            className="p-2 w-20 text-center bg-white border border-gray-300 rounded-md shadow-sm focus:border-green-500 focus:ring-green-500 focus:outline-none"
                                            required
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </section>
            </div>


        </div>
    </div>
}
