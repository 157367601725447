const AboutUS = () => {
    return (
        <div className="container mx-auto min-h-screen">
            <div className="bg-white shadow-md rounded-lg overflow-hidden">
                <div className="bg-cover bg-center h-56 p-4" style={{ backgroundImage: "url('https://t4.ftcdn.net/jpg/06/51/89/81/360_F_651898136_VYg9YknaxQKK1DYYopfmaUI1MjUBRRwl.jpg')" }}>
                    <div className="flex justify-center items-center h-full">
                        <h1 className="text-4xl font-bold text-white text-center">About Us</h1>
                    </div>
                </div>
                <div className="p-6">
                    <section className="mb-8">
                        <h2 className="text-3xl font-semibold mb-4">Our Story</h2>
                        <p className="text-gray-700 text-lg leading-relaxed">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sit amet orci at ligula faucibus feugiat vel vel lorem. Vivamus aliquam, erat at vestibulum finibus, enim purus auctor elit, nec porttitor risus magna ac turpis. Nulla et convallis lorem, sit amet efficitur purus. Donec vel urna et augue volutpat bibendum. Quisque eu dui ut velit scelerisque suscipit.
                        </p>
                    </section>
                    <section className="mb-8">
                        <h2 className="text-3xl font-semibold mb-4">Our Mission</h2>
                        <p className="text-gray-700 text-lg leading-relaxed">
                            Curabitur vitae magna ut ex pellentesque ultricies. Suspendisse potenti. Donec pulvinar varius lacus, a congue metus ultricies at. Aenean convallis venenatis urna, vel tincidunt eros congue vel. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
                        </p>
                    </section>
                    <section className="mb-8">
                        <h2 className="text-3xl font-semibold mb-4">Our Team</h2>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                            <div className="bg-gray-100 p-4 rounded-lg shadow-md">
                                <img className="w-full h-48 object-cover rounded-t-lg" src="https://img.freepik.com/free-photo/young-bearded-man-with-striped-shirt_273609-5677.jpg" alt="Team Member 1" />
                                <div className="p-4">
                                    <h3 className="text-2xl font-semibold mb-2">John Doe</h3>
                                    <p className="text-gray-600">CEO</p>
                                </div>
                            </div>
                            <div className="bg-gray-100 p-4 rounded-lg shadow-md">
                                <img className="w-full h-48 object-cover rounded-t-lg" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTO2vBQ1vOla9pPM6M0ZsYZb7OckCS21cgN_Q&s" alt="Team Member 2" />
                                <div className="p-4">
                                    <h3 className="text-2xl font-semibold mb-2">Jane Smith</h3>
                                    <p className="text-gray-600">CTO</p>
                                </div>
                            </div>
                            <div className="bg-gray-100 p-4 rounded-lg shadow-md">
                                <img className="w-full h-48 object-cover rounded-t-lg" src="https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg?cs=srgb&dl=pexels-simon-robben-55958-614810.jpg&fm=jpg" alt="Team Member 3" />
                                <div className="p-4">
                                    <h3 className="text-2xl font-semibold mb-2">Alice Johnson</h3>
                                    <p className="text-gray-600">CFO</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}

export default AboutUS;
