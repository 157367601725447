import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { Accordion, AccordionDetails, AccordionSummary, Breadcrumbs, Button, Card, CardContent, CardHeader, CircularProgress, Table, TableHead, TextField, Typography } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { _api } from '../utilities/api';
import HomeSettingsForm from './HomeSettingsForm';

const ImageWrapper = ({ img }) => {

    let pp;
    if (!Boolean(img)) {
        pp = "https://cpworldgroup.com/wp-content/uploads/2021/01/placeholder.png"
    }
    return <div className="image_wrapper" style={{ width: 100, height: 100 }}>
        <img width={100} height={100} style={{ objectFit: "cover" }} src={pp || `${process.env.REACT_APP_SERVER_URL}/uploads/homesettings/${img}`} />
    </div>
}

const ListUpdate = () => {
    const navigate = useNavigate();
    const [isPageLoad, setPageLoad] = useState(false);
    const [homeSettings, setHomeSettings] = useState({})

    useEffect(() => {
        _api({
            url: `/home_settings`
        }).then(({ error, data }) => {
            console.log({ data })
            if (!error) {
                setHomeSettings(data)
            }
            setPageLoad(true);
        });
    }, []);


    return (
        <>
            {!isPageLoad ? (
                <CircularProgress thickness={5} />
            ) : (
                <HomeSettingsForm initialData={homeSettings} />
            )}
        </>
    );
};

export default ListUpdate;
