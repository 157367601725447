const ContactUS = () => {
    return (<>
        <div className="container" style={{ minHeight: "100vh", paddingTop: "10px" }}>
            <section className="mb-8">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 pt-5">
                    <div>
                        <h3 className="text-2xl font-semibold mb-4">Our Location</h3>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.0191288179425!2d144.9632803153585!3d-37.80815697975157!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf577a77b7b6c08b7!2sEnvato!5e0!3m2!1sen!2sau!4v1622115208663!5m2!1sen!2sau"
                            width="100%"
                            height="300"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                        ></iframe>
                        <p className="text-gray-700 text-lg leading-relaxed mt-4">
                            123 Main Street, City Name, State, Country
                        </p>
                        <p className="text-gray-700 text-lg leading-relaxed">
                            Email: contact@company.com
                        </p>
                        <p className="text-gray-700 text-lg leading-relaxed">
                            Phone: (123) 456-7890
                        </p>
                    </div>
                    <div>
                        <h3 className="text-2xl font-semibold mb-4">Send Us a Message</h3>
                        <form className="space-y-4">
                            <div>
                                <label className="block text-gray-700">Name</label>
                                <input
                                    type="text"
                                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-600"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700">Email</label>
                                <input
                                    type="email"
                                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-600"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700">Message</label>
                                <textarea
                                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-600"
                                    rows="4"
                                    required
                                ></textarea>
                            </div>
                            <button
                                type="submit"
                                className="w-full bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-600"
                            >
                                Send Message
                            </button>
                        </form>
                    </div>
                </div>
            </section>

        </div>
    </>)
}

export default ContactUS;