import React, { useState, useEffect } from 'react';
import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Checkbox from '@mui/joy/Checkbox';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import BadgeRoundedIcon from '@mui/icons-material/BadgeRounded';
import GoogleIcon from '@mui/icons-material/Google';
import { _api } from "../../utilities/api";
import { Link, useNavigate } from 'react-router-dom';

export default function Register() {
    const navigate = useNavigate();
    const [method, setMethod] = useState("PASSWORD");

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const [step, setStep] = useState(1)

    return (
        <div className='flex itemsc-center justify-center'>
            <Box

                sx={(theme) => ({
                    width:
                        'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
                    transition: 'width var(--Transition-duration)',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    position: 'relative',
                    zIndex: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    backdropFilter: 'blur(12px)',
                    backgroundColor: 'rgba(255 255 255 / 0.2)',
                    [theme.getColorSchemeSelector('dark')]: {
                        backgroundColor: 'rgba(19 19 24 / 0.4)',
                    },
                })}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100vh',
                        width:
                            'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
                        maxWidth: '100%',
                        px: 2,
                    }}
                >
                    <Box
                        component="header"
                        sx={{
                            height: "40px",
                            width: "100%"
                        }}
                    />
                    <Box
                        component="main"
                        sx={{
                            my: 'auto',
                            py: 2,
                            pb: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            width: 400,
                            maxWidth: '100%',
                            mx: 'auto',
                            borderRadius: 'sm',
                            '& form': {
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            },
                            [`& .${formLabelClasses.asterisk}`]: {
                                visibility: 'hidden',
                            },
                        }}
                    >
                        <Stack gap={4} sx={{ mb: 0 }}>
                            <div className="flex flex-col items-center justify-center">
                                <img src="/images/logo-main.png" className="w-[170px] mb-10 cursor-pointer" onClick={() => {
                                    navigate("/")
                                }} />
                                <Typography level="h3">Login Here</Typography>
                            </div>
                        </Stack>
                        {
                            step == 1 ?
                                <Stack gap={4} sx={{ mt: 2 }}>
                                    <form
                                        onSubmit={(event) => {
                                            event.preventDefault();
                                            const formElements = event.currentTarget.elements;

                                            if (method == "PASSWORD") {

                                                const data = {
                                                    email: formElements.email.value,
                                                    password: formElements.password.value,
                                                };
                                                _api({
                                                    method: "POST",
                                                    url: "/auth/directLogin",
                                                    data,
                                                }).then(({ error, data }) => {
                                                    if (error) {
                                                        alert(error.response.data.message)
                                                        return;
                                                    }
                                                    alert(data?.message)
                                                    if (data?.success) {
                                                        window.localStorage.setItem("gms_access_token", data?.token)
                                                        window.localStorage.setItem("gms_user", JSON.stringify(data?.user))
                                                        navigate("/")
                                                    }
                                                })
                                            }

                                            if (method == "OTP") {
                                                const data = {
                                                    email: formElements.email.value,
                                                };
                                                _api({
                                                    method: "POST",
                                                    url: "/auth/sendOtp",
                                                    data,
                                                }).then(({ error, data }) => {
                                                    if (error) {
                                                        alert(error.response.data.message)
                                                        return;
                                                    }
                                                    alert(data?.message)
                                                    setStep(2)
                                                })
                                            }


                                        }}
                                    >
                                        <FormControl>
                                            <FormLabel>Email</FormLabel>
                                            <Input value={email} onChange={e => setEmail(e.target.value)} type="email" name="email" placeholder='Enter Your Email' required />
                                        </FormControl>
                                        {
                                            method == "PASSWORD" && <FormControl>
                                                <FormLabel>Password</FormLabel>
                                                <Input type="text" name="password" placeholder='Enter Password' required />
                                            </FormControl>
                                        }
                                        <Stack gap={2} sx={{ mt: 2 }}>
                                            <Button type="submit" fullWidth>
                                                {method == "PASSWORD" ? "Login" : "Send OTP"}
                                            </Button>
                                            <p className='mx-auto'>Or</p>
                                            {
                                                method == "PASSWORD" ?
                                                    <Button onClick={() => setMethod("OTP")} className='' variant='soft'>Login with OTP</Button> :
                                                    <Button onClick={() => setMethod("PASSWORD")} className='' variant='soft'>Login with password</Button>
                                            }
                                            <Box sx={{ textAlign: "center" }}>
                                                <Typography level="body-sm" textAlign="center">
                                                    {new Date().getFullYear()} All rights reserved @ Copyright
                                                </Typography>
                                            </Box>
                                        </Stack>
                                    </form>
                                </Stack> :
                                <Stack gap={4} sx={{ mt: 0 }}>
                                    <form
                                        onSubmit={(event) => {
                                            event.preventDefault();
                                            const formElements = event.currentTarget.elements;

                                            const data = {
                                                email,
                                                otp: formElements.otp.value
                                            };
                                            _api({
                                                method: "POST",
                                                url: "/auth/login",
                                                data,
                                            }).then(({ error, data }) => {
                                                if (error) {
                                                    alert(error.response.data.message)
                                                    return;
                                                }
                                                alert(data?.message)
                                                if (data?.success) {
                                                    window.localStorage.setItem("gms_access_token", data?.token)
                                                    window.localStorage.setItem("gms_user", JSON.stringify(data?.user))
                                                    navigate("/")
                                                }
                                            })
                                        }}
                                    >

                                        <Typography mb={5} textAlign={"center"}>A one time password (Otp) has been sent your email
                                            <span className='text-blue-800'> {email} &nbsp;
                                                <button className='text-black underline' onClick={() => setStep(1)}>Wrong Email?</button>
                                            </span>
                                        </Typography>
                                        <FormControl required>
                                            <FormLabel>Email OTP</FormLabel>
                                            <Input type="text" name="otp" placeholder='XXXX' />
                                        </FormControl>
                                        <Stack gap={2} sx={{ mt: 2 }}>
                                            <Button type="submit" fullWidth>
                                                Login
                                            </Button>
                                        </Stack>
                                        <Box sx={{ textAlign: "center" }}>
                                            <Typography level="body-sm" textAlign="center">
                                                {new Date().getFullYear()} All rights reserved @ Copyright
                                            </Typography>
                                        </Box>
                                    </form>
                                </Stack>
                        }
                    </Box>
                    {/* <Box component="footer" sx={{ py: 3 }}>
<Typography level="body-xs" textAlign="center">
© Your company {new Date().getFullYear()}
</Typography>
</Box> */}
                </Box>
            </Box>
        </div>
    );
}
