import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const MainLayout = () => {
    return (<>
        <div className="body-wrap">
            <Navbar />
            <div id="content" className="container mx-auto mt-[70px] p-0">
                <main>
                    <Outlet />
                </main>
            </div>
            <Footer />
        </div>
    </>)
}

export default MainLayout;