import { Route, Routes, useNavigate } from "react-router-dom";
import MainLayout from "./layouts/MainLayout";
import Home from "./pages/Home";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import OrdersList from "./pages/OrdersList";
import Profile from "./pages/Auth/Profile";
import Store from "./pages/Store";
import NewOrder from "./pages/NewOrder";
import { SingleProduct } from "./pages/SingleProduct";
import { Checkout } from "./pages/Checkout";
import AboutUS from "./pages/About";
import ContactUS from "./pages/ContactUS";
import Dashboard from "./pages/Dashboard";
import HomeSettings from "./pages/HomeSettings";


function App() {
  return (
    <Routes>
      <Route path="/login" element={<Register />} />
      <Route path="/" element={<MainLayout />}>
        <Route index element={<Home />} />
        <Route path="/orders" element={<OrdersList />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/new-order" element={<NewOrder />} />
        <Route path="/about" element={<AboutUS />} />
        <Route path="/contact" element={<ContactUS />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/home-settings" element={<HomeSettings />} />
      </Route>
    </Routes >
  );
}

export default App;
